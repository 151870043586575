import { Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { ReactComponent as QuestionIcon } from '../../images/icon/Question_light.svg';
import { useStyles } from '../Templates/CommonStyle';

type IProps = {
  text: string;
  type?: 'General' | 'Moderate' | 'Strong' | 'Strong-Hilight';
  tooltipText?: string;
  href?: string;
};

const CustomAnnotationComponent = React.memo<IProps>(
  ({ text, type = 'Moderate', tooltipText , href}): JSX.Element => {
    const classes = useStyles();

    const styles = useMemo(() => {
      const baseStyles = {
        textColor: '#313133',
        borderColor: '#313133',
        iconColor: '#313133',
        backgroundColor: '',
        iconFillColor: '#fff',
      };

      const typeStyles: Record<string, Partial<typeof baseStyles>> = {
        General: {},
        Moderate: {
          textColor: '#313133',
          borderColor: '#939598',
          iconColor: '#939598',
        },
        Strong: {
          textColor: '#009DE1',
          borderColor: '#009DE1',
          iconColor: '#009DE1',
        },
        'Strong-Hilight': {
          textColor: '#fff',
          borderColor: '#009DE1',
          iconColor: '#fff',
          backgroundColor: '#009DE1',
          iconFillColor: '#009DE1',
        },
      };

      return {
        ...baseStyles,
        ...(typeStyles[type] || {}),
      };
    }, [type]);

    return type === 'General' ? (
      <div>
        {' '}
        <Typography
          sx={{
            fontWeight: 'bold',
            lineHeight: '20px',
            fontSize: '14px',
            color: '#313133',
            letterSpacing: '0.09px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          {text}
          <Tooltip
            placement="top"
            title={tooltipText}
            arrow
            sx={{ marginTop: 0 }}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-7, -7],
                  },
                },
              ],
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  minWidth: '126px',
                  textAlign: 'center',
                  fontWeight: 300,
                  fontSize: '12px',
                  backgroundColor: '#313133',
                  lineHeight: 1.35,
                },
              },
            }}
          >
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                padding: '0',
                marginLeft: '7px',
                display: 'flex',
              }}
            >
              <QuestionIcon className={classes?.tooltipIcon} />
            </a>
          </Tooltip>
        </Typography>
      </div>
    ) : (
      <div
        style={{
          border: `1px solid ${styles.borderColor}`,
          borderRadius: '3px',
          backgroundColor: styles.backgroundColor,
        }}
      >
        <div style={{ display: 'flex'}}>
          <div
            style={{
              padding: '5px 0px 0px 15px',
            }}
          >
            <svg
              id="Group_644"
              data-name="Group 644"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <circle
                id="Ellipse_106"
                data-name="Ellipse 106"
                cx="8.5"
                cy="8.5"
                r="8.5"
                fill={styles.iconColor}
              />
              <text
                id="_"
                data-name="!"
                transform="translate(7 13)"
                fill={styles.iconFillColor}
                fontSize="12"
                fontFamily="Helvetica"
              >
                <tspan x="0" y="0">
                  !
                </tspan>
              </text>
            </svg>
          </div>
          <Typography
            sx={{
              padding: '8px 26px 8px 0px',
              fontSize: '14px',
              fontFamily: "'Noto Sans JP', sans-serif",
              marginLeft: '10px',
              color: styles.textColor,
            }}
          >
            {text}
          </Typography>
        </div>
      </div>
    );
  },
);

export default CustomAnnotationComponent;
