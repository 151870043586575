/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Box,
  Button,
  Typography,
  Tooltip,
  Skeleton,
  Avatar,
  ClickAwayListener,
  List,
  ListItemButton,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { useEffect, useState } from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  NoticeProps,
  GroupBase,
  OptionProps,
} from 'react-select';
import SimpleBarReact from 'simplebar-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from '../Templates/CommonStyle';
import type { UserDetailInput } from '../../types/inputs/userDetailInput';
import useUserDetail from '../../hooks/useUserDetail';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
// import useUserDelete from '../../hooks/useUserDelete';
import useUserDeactivate from '../../hooks/useUserDeactivate';
import useUserReinvite from '../../hooks/useUserReinvite';
import LogoutDialog from '../Templates/LogoutDialog';
import DetailsTemplate from '../Templates/DetailsTemplate';
import RegistrationInformationCard from '../Modecules/RegistrationInformationCard';
import { ReactComponent as RightWhite } from '../../images/icon/Right_white.svg';
import { ReactComponent as MenuIcon } from '../../images/icon/menu.svg';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';
import { ReactComponent as ConsoleSetting } from '../../images/icon/console-setting.svg';
import { ReactComponent as Deactivate } from '../../images/icon/deactivate.svg';
import { ReactComponent as Activate } from '../../images/icon/activate.svg';
import { ReactComponent as ModalDeactiveIcon } from '../../images/icon/deactivate-modal.svg';
import { ReactComponent as ModalActiveIcon } from '../../images/icon/activate-modal.svg';
import {
  FEATURE_PERMISSION,
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
} from '../../constants/constants';
import DeleteDialog from '../Atoms/DeleteDialog';
import { getTimeFormat } from '../../utils/devicelist/deviceListUtil';
import Loader from '../Atoms/Loader';
import useAccountInfo from '../../hooks/useAccountInfo';
import { sendGAEvent } from '../../hooks/useGAEvent';
import DisabledInputField from '../Atoms/DisabledInputField';
import customStylesMd from '../../utils/customizeReactSelect/customStylesMd';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';

interface ICategory {
  // eslint-disable-next-line camelcase
  auth_id: number;
  value: string;
}

/**
 * ユーザー詳細(ユーザー情報編集)画面 コンポーネント
 * @returns
 */
const UserDetail = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  dayjs.extend(utc);
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [lastLogin, setLastLogin] = useState<string>('');
  const [lastLoginColor, setLastLoginColor] = useState<boolean>(false);

  const { localeLanguage } = useLocale();
  const { accountInfo } = useAccountInfo();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', '3_point_leader');
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuId = isMenuOpen ? 'simple-popper' : undefined;

  // バリデーションフォーム作成
  const {
    handleSubmit,
    // formState: { errors },
    setValue,
    control,
  } = useForm<UserDetailInput>({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
  });

  const {
    isCallingApi,
    isCallingSaveApi,
    onClickSaveButton,
    userInfoData,
    authList,
    userDetailError,
    isSaveEnabled,
    handleOnChangeAuthId,
    registeredAlreadyStatusFetch,
    setUpdateInfo,
  } = useUserDetail();

  const { isCallingReInviteApi, onClickReInviteButton, invitationStatus } =
    useUserReinvite();
  const [userDeactivateStatus, setUserDeactivatedStatus] = useState(false);

  useEffect(() => {
    if (userInfoData.accountStatus === t('personalSetting.label.registered')) {
      setUserDeactivatedStatus(false);
    }
    if (userInfoData.accountStatus === t('personalSetting.label.suspend')) {
      setUserDeactivatedStatus(true);
    }
  }, [userInfoData.accountStatus, t]);
  useEffect(() => {
    if (invitationStatus === t('personalSetting.label.registered')) {
      registeredAlreadyStatusFetch(invitationStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationStatus]);

  // auth list option
  const authListOpt = authList?.map((value) => ({
    value: value.roleCode,
    label: value.roleName,
  }));

  // Warning: Cannot update a componentが出るためuseEffectで囲む
  useEffect(() => {
    // ユーザ取得APIから返却された権限名に紐づく権限IDを設定
    if (userInfoData?.roleCode) {
      setValue('roleCode', userInfoData?.roleCode);
    }

    // setValueに変更があった場合無限にレンダリングが走ってしまう恐れがあるため除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoData?.roleCode]);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      !accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
        FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.VIEWDETAILS,
      )
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [pathname]);

  // ユーザ削除ダイアログ hooks
  const {
    isCallingUserDeleteApi,
    onClickOpenUserDeleteDialog,
    onClickDeactivateUserButton,
    onClickCancelButton,
    isOpenUserDeleteModal,
    userDeleteError,
    userStatusChange,
  } = useUserDeactivate();
  // Passing Deactivate hooks update status recall userInfo
  useEffect(() => {
    if (userStatusChange) {
      setUpdateInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatusChange]);
  // customize react-select
  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.DropdownIndicator {...props}>
      <ArrowDown className={classes.selectArrow} />
    </components.DropdownIndicator>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const Option = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.Option {...props}>
      {props.children}
      {props.isSelected && <CheckIcon />}
    </components.Option>
  );
  // custom message when there are no options
  // eslint-disable-next-line react/no-unstable-nested-components
  const NoOptionsMessage = (
    props4: NoticeProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.NoOptionsMessage {...props4}>
      {t('common.label.noOptions')}
    </components.NoOptionsMessage>
  );

  // End customize react-select
  // Tooltip
  const [tooltipEnabledUserData, setTooltipEnabledUserData] = useState(false);
  const handleShowTooltipUserData = (event: React.MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;
    const getMaxWidth = element.style.maxWidth;

    // Set the element's overflow to visible
    element.style.overflow = 'visible';
    element.style.maxWidth = 'fit-content';

    // Get the scroll width
    const scrollWidth = element.getBoundingClientRect().width;

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';
    element.style.maxWidth = getMaxWidth;

    if (scrollWidth > clientWidth) {
      setTooltipEnabledUserData(true);
    } else {
      setTooltipEnabledUserData(false);
    }
  };

  const [tooltipEnabledGroupName, setTooltipEnabledGroupName] = useState(false);
  const handleShouldShowGroupNameTooltip = (
    event: React.MouseEvent<Element>,
  ) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;

    // Set the element's overflow to visible
    element.style.overflow = 'visible';
    element.style.maxWidth = 'fit-content';

    // Get the scroll width
    const scrollWidth = element.getBoundingClientRect().width;

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';
    element.style.maxWidth = '142px';

    if (scrollWidth > clientWidth) {
      setTooltipEnabledGroupName(true);
    } else {
      setTooltipEnabledGroupName(false);
    }
  };
  const nameWord = userInfoData?.userName?.trim().charAt(0).toUpperCase();

  useEffect(() => {
    const dateValue = getTimeFormat(userInfoData?.lastLoginDt, t);
    setLastLogin(dateValue?.date);
    setLastLoginColor(dateValue?.recentUsed);
  }, [userInfoData, t]);

  // console.log(
  //   'status',
  //   userInfoData?.accountStatus === t('personalSetting.label.suspend'),
  //   userInfoData?.accountStatus,
  //   t('personalSetting.label.suspend'),
  // );

  return (
    <DetailsTemplate titleText={t('userDetail.pageTitle')}>
      <Box sx={{ width: '100%' }}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onClickSaveButton)}>
          <Box className={classes.userDeatilBtnCard}>
            <Button
              id="userDetailSave"
              type="submit"
              variant="contained"
              className={classes.userDeatilSaveBtn}
              disableRipple
              disabled={
                userDetailError.authListErrorType !== null ||
                userDetailError.userInfoErrorType !== null ||
                !isSaveEnabled ||
                isCallingSaveApi
              }
            >
              {isCallingSaveApi ? <Loader colorCode="white" /> : <RightWhite />}
              <Typography className={classes.userDeatilSaveText}>
                {t('userDetail.button.save')}
              </Typography>
            </Button>
            {accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
              FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.DELETE,
            ) && (
              <Button
                id="detailsButton"
                variant="contained"
                className={classes.userDeatilIconBtn}
                aria-controls={menuId}
                aria-describedby={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                disabled={
                  userInfoData?.accountStatus !==
                    t('personalSetting.label.suspend') &&
                  userInfoData?.accountStatus !==
                    t('personalSetting.label.registered')
                }
              >
                <MenuIcon />
              </Button>
            )}
            <Popper
              placement="bottom-end"
              id={menuId}
              open={isMenuOpen}
              anchorEl={anchorEl}
              popperOptions={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-16, -11],
                    },
                  },
                ],
              }}
              className={classes.remoteNDelete}
            >
              <ClickAwayListener onClickAway={handleMenuClose}>
                <List>
                  <ListItemButton
                    disableRipple
                    onClick={onClickOpenUserDeleteDialog}
                    sx={{
                      '&:hover': {
                        color:
                          userInfoData?.accountStatus ===
                          t('personalSetting.label.suspend')
                            ? '#313133'
                            : '#E1341E',
                        backgroundColor: '#EAEAEB',
                        '& svg': {
                          '& path': {
                            fill:
                              userInfoData?.accountStatus ===
                              t('personalSetting.label.suspend')
                                ? '#313133'
                                : '#E1341E',
                          },
                        },
                      },
                    }}
                  >
                    {userInfoData?.accountStatus ===
                    t('personalSetting.label.suspend') ? (
                      <Activate />
                    ) : (
                      <Deactivate />
                    )}
                    <p style={{ padding: '0', margin: '0' }}>
                      {userInfoData?.accountStatus ===
                      t('personalSetting.label.suspend')
                        ? t('userDetail.label.userActivation')
                        : t('userDetail.label.userDeactivation')}
                    </p>
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Popper>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '30px',
            }}
            className="user-detail-main-block-res"
          >
            <Box className="profile-card-res" sx={{ width: '50%' }}>
              <Box className={classes.userDeatilDataCard}>
                <Box
                  className={`${
                    classes.userDeatilDataCardInfoBox
                  } ${'user-detail-profile-info-res'}`}
                >
                  <Box
                    className={`${
                      classes.userDeatilDataCardInfoBoxTwo
                    } ${'user-detail-profile-info-res'}`}
                  >
                    {/* eslint-disable no-nested-ternary */}
                    {isCallingApi ? (
                      <Skeleton
                        variant="circular"
                        animation="wave"
                        sx={{
                          p: '0px',
                          width: '120px',
                          height: '120px',
                          borderRadius: '50%',
                        }}
                      />
                    ) : userInfoData?.imageUrl ? (
                      <img
                        alt="pic"
                        src={userInfoData?.imageUrl}
                        style={{
                          width: '120px',
                          height: '120px',
                          borderRadius: '50%',
                        }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: '#F09A8F',
                          fontSize: '40px',
                          width: '120px',
                          height: '120px',
                        }}
                      >
                        {nameWord}
                      </Avatar>
                    )}
                    {isCallingApi ? (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            p: '0px',
                            width: '170px',
                            height: '36px',
                            marginBottom: '4px',
                          }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            p: '0px',
                            width: '90px',
                            height: '20px',
                            marginBottom: '4px',
                          }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ p: '0px', width: '170px', height: '20px' }}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Tooltip
                          placement="top"
                          title={userInfoData?.userName}
                          onMouseEnter={handleShowTooltipUserData}
                          disableHoverListener={!tooltipEnabledUserData}
                          arrow
                          sx={{ marginTop: 0 }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [-7, -7],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.tooltipStyle,
                            },
                          }}
                        >
                          <Typography
                            className={classes.userDeatilDataCardInfoName}
                          >
                            {userInfoData?.userName}
                          </Typography>
                        </Tooltip>
                        <Typography
                          className={classes.userDeatilDataCardInfoRole}
                        >
                          {userInfoData?.roleName}
                        </Typography>
                        <Tooltip
                          placement="top"
                          title={userInfoData?.mailAddress}
                          onMouseEnter={handleShowTooltipUserData}
                          disableHoverListener={!tooltipEnabledUserData}
                          arrow
                          sx={{ marginTop: 0 }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [-7, -7],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.tooltipStyle,
                            },
                          }}
                        >
                          <Typography
                            className={classes.userDeatilDataCardInfoEmail}
                          >
                            {userInfoData?.mailAddress}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>

                  <Box
                    className={`${
                      classes.userDeatilLastActiveBox
                    } ${'last-login-info'}`}
                  >
                    <Typography
                      className={classes.userDeatilLastActiveText}
                      sx={{
                        '@media screen and (min-width:1420px) and (max-width:1890px)':
                          {
                            width:
                              localeLanguage === LOCALE_CODE.DE ||
                              localeLanguage === LOCALE_CODE.FR
                                ? 'min-content'
                                : 'max-content',
                          },
                        '@media screen and (min-width:1420px) and (max-width:1645px)':
                          {
                            width:
                              localeLanguage === LOCALE_CODE.JA
                                ? 'max-content'
                                : 'min-content',
                          },
                      }}
                    >
                      {t('userDetail.label.lastLoggedIn')}:
                    </Typography>
                    {isCallingApi ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          width: '81px',
                          height: '21px',
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '15px',
                          color: lastLoginColor ? '#4DC176' : '#F6AB00',
                        }}
                      >
                        {lastLogin === 'invalid' ? '' : lastLogin}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {t('userDetail.label.name')}
                </Typography>
                {isCallingApi ? (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '38px',
                      borderRadius: '6px',
                      marginBottom: '12px',
                    }}
                  />
                ) : (
                  <DisabledInputField text={userInfoData?.userName} />
                )}
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    marginTop: '4px',
                  }}
                >
                  {t('userDetail.label.email')}
                </Typography>
                {isCallingApi ? (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '38px',
                      borderRadius: '6px',
                    }}
                  />
                ) : (
                  <DisabledInputField text={userInfoData?.mailAddress} />
                )}
              </Box>
            </Box>
            <Box className="profile-card-res" sx={{ width: '50%' }}>
              <RegistrationInformationCard
                isButtonAndStatus
                isCallingApi={isCallingApi}
                isCallingReInviteApi={isCallingReInviteApi}
                onClickReInviteButton={() => {
                  onClickReInviteButton(userInfoData?.mailAddress);

                  // GA tag
                  sendGAEvent('press_btn', 'btn_name', 're_invite');
                  sendGAEvent(
                    'press_btn',
                    're_invite_value',
                    userInfoData?.mailAddress,
                  );
                }}
                status={
                  invitationStatus === t('personalSetting.label.reInvited')
                    ? invitationStatus
                    : userInfoData?.accountStatus
                }
                statusColor={
                  invitationStatus === t('personalSetting.label.reInvited')
                    ? 'SKYBLUE'
                    : userInfoData?.statusColor
                }
                regDate={
                  userInfoData?.registeredDt &&
                  dayjs(userInfoData?.registeredDt).format('YYYY/MM/DD')
                }
                invDate={
                  userInfoData?.invitedDt &&
                  dayjs(userInfoData?.invitedDt).format('YYYY/MM/DD')
                }
                reInviteEnable={
                  // eslint-disable-next-line
                  accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERINVITE?.includes(
                    FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERINVITE.EDIT,
                  )
                }
              />
              <Box
                className={`${
                  classes.userDeatilConsoleSettingCard
                } ${'user-details-setting-card'}`}
              >
                <Box className={classes.userDeatilConsoleSettingTitleBox}>
                  <Typography
                    sx={{
                      minWidth: '24px',
                    }}
                  >
                    <ConsoleSetting />
                  </Typography>

                  <Typography
                    className={classes.userDeatilConsoleSettingTitleText}
                  >
                    {t('userDetail.label.conSet')}
                  </Typography>
                </Box>
                <Typography
                  className={classes.userDeatilConsoleSettingRoleText}
                >
                  {t('userDetail.label.roleName')}
                </Typography>
                {isCallingApi ? (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '38px',
                      borderRadius: '6px',
                    }}
                  />
                ) : (
                  <Controller
                    name="roleCode"
                    render={({ field: { onChange, value, name } }) => (
                      <Select
                        placeholder={
                          <Typography
                            sx={{ fontSize: '14px', color: '#939598' }}
                          >
                            {t('common.label.select')}
                          </Typography>
                        }
                        value={authListOpt.find((c) => c.value === value)}
                        name={name}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore:next-line
                        onChange={(selectedOption: ICategory) => {
                          onChange(selectedOption.value);
                          handleOnChangeAuthId(selectedOption.value);

                          const foundRolePos = authListOpt.findIndex(
                            (obj) =>
                              obj.value === parseInt(selectedOption.value, 10),
                          );

                          // GA tag
                          sendGAEvent(
                            'select_list',
                            'list_number',
                            'select_role_list',
                          );
                          sendGAEvent(
                            'select_list',
                            'select_role_list_value',
                            authListOpt[foundRolePos].label,
                          );
                        }}
                        options={authListOpt}
                        isSearchable={false}
                        components={{
                          DropdownIndicator,
                          Option,
                          NoOptionsMessage,
                        }}
                        className={classes.reactSelect}
                        classNamePrefix="react-select"
                        styles={customStylesMd()}
                        isDisabled={
                          // eslint-disable-next-line
                          !accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERROLE?.includes(
                            FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERROLE.EDIT,
                          )
                        }
                      />
                    )}
                    control={control}
                  />
                )}
                <Typography
                  className={classes.userDeatilConsoleSettingGroupText}
                >
                  {t('userDetail.label.groups')}
                </Typography>
                <Box className={classes.userDeatilConsoleSettingGroupsBoxCard}>
                  <SimpleBarReact
                    style={{
                      height: '93px',
                      marginRight: '4px',
                    }}
                    // autoHide={false}
                  >
                    <Box className={classes.userDeatilConsoleSettingGroupsBox}>
                      {userInfoData?.groups?.map((name) => (
                        <Box
                          className={
                            classes.userDeatilConsoleSettingSingleGroupBox
                          }
                        >
                          <Tooltip
                            placement="top"
                            title={name}
                            onMouseEnter={handleShouldShowGroupNameTooltip}
                            disableHoverListener={!tooltipEnabledGroupName}
                            arrow
                            sx={{ marginTop: 0 }}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [-7, -7],
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                className: classes.tooltipStyle,
                              },
                            }}
                          >
                            <Typography
                              className={
                                classes.consoleSettingCardSingleGroupName
                              }
                            >
                              {name}
                            </Typography>
                          </Tooltip>
                        </Box>
                      ))}
                    </Box>
                  </SimpleBarReact>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
        <DeleteDialog
          icon="helllos"
          onClose={() => onClickCancelButton()}
          isCallingApi={isCallingUserDeleteApi}
          open={isOpenUserDeleteModal}
          title={
            userInfoData?.accountStatus === t('personalSetting.label.suspend')
              ? t('userDetail.activateWarning')
              : t('userDetail.deactivateWarning')
          }
          deleteModalIcon={
            userInfoData?.accountStatus ===
            t('personalSetting.label.suspend') ? (
              <ModalActiveIcon />
            ) : (
              <ModalDeactiveIcon />
            )
          }
          btnBgColor={
            userInfoData?.accountStatus === t('personalSetting.label.suspend')
              ? '#009DE1'
              : '#E1341E'
          }
          btnBgColorHover={
            userInfoData?.accountStatus === t('personalSetting.label.suspend')
              ? '#007EB4'
              : '#CC240F'
          }
          btnTextNegative={t('groupDetail.label.cancelBtn')}
          btnTextPositive={
            userInfoData?.accountStatus === t('personalSetting.label.suspend')
              ? t('userDetail.label.activateButton')
              : t('userDetail.label.deactivateButton')
          }
          btnLoadingText={
            userInfoData?.accountStatus === t('personalSetting.label.suspend')
              ? t('userDetail.label.activatingBtn')
              : t('userDetail.label.deactivatingBtn')
          }
          onSubmit={() =>
            onClickDeactivateUserButton(
              userInfoData.mailAddress,
              userDeactivateStatus,
            )
          }
        />

        {userDeleteError.userDeleteErrorType !== null &&
          userDeleteError.userDeleteErrorType ===
            SERVER_MESSAGE.DELETE_NOT_ALLOWED && (
            <LogoutDialog
              isModalOpen
              logoutModal={userDetailError.onClickLogoutButton}
              message={t('userDeleteDialog.deleteOwn')}
            />
          )}
      </Box>
    </DetailsTemplate>
  );
};

export default UserDetail;
