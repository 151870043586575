import { createSlice } from '@reduxjs/toolkit';

export interface LoadingState {
    globalLoading: boolean;
}

const initialoadinglState: LoadingState = {
    globalLoading: false,
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState : initialoadinglState,
    reducers: {
        setStartLoading:  (state) => ({ ...state, globalLoading: true }),
        setEndLoading: (state) => ({ ...state, globalLoading: false }),

    },
});

export const loadingActions = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;