/* eslint-disable import/prefer-default-export */
import callUpdateUserInfoApi from '../../apis/callUpdateUserInfoApi';
import {
  isErrorType,
} from '../../types/apis/apiErrorTypeGuard';
import type { GetUpdateUserInfoApiResult,GetUpdateUserInfoApiResponse } from '../../types/apis/getUpdateUserInfoApi.d';

import {
  ALL_ERROR_TYPE,
  SERVER_MESSAGE,
} from '../../constants/constants';


/*eslint-disable*/
export const fetchUpdateUserInfo = async (): Promise<GetUpdateUserInfoApiResult> => {
  try {
    const apiResponse: GetUpdateUserInfoApiResponse = await callUpdateUserInfoApi();
    switch (apiResponse.message) {
      case SERVER_MESSAGE.FETCH_OK:
        return {
          message: SERVER_MESSAGE.FETCH_OK,
          details: apiResponse.details,
        };
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
        case SERVER_MESSAGE.INVALID_AUTH0_TOKEN:
          throw SERVER_MESSAGE.INVALID_AUTH0_TOKEN;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      details:{
        locale: '',
        company: '',
        imageUrl: '',
      },
    };
  }
};

