import { API_URL } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';
import type {
//   UserDeleteApiParameter,
  UserDeleteApiResponse,
  UserDeactivateParameter
} from '../types/apis/userDeleteApi';

/**
 * ユーザ削除API 結果コード
 */
export const USER_DELETE_API_RESULT_CODE = {
  // OK
  AUTH_DATA_NOT_FOUND: 'INFO_NO_AUTH_DATA',
  DELETE_OK: 'SUCCESSFULLY_DELETED',
  USER_NOT_FOUND: 'INFO_NO_USER',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
} as const;

/**
 * ユーザ削除エラータイプ
 */
export type USER_DELETE_ERROR_TYPE =
  typeof USER_DELETE_API_RESULT_CODE[keyof typeof USER_DELETE_API_RESULT_CODE];

/**
 * ユーザ削除API呼び出し
 *
 * @param requestParams リクエストパラメータ
 */
/*eslint-disable*/
const callUserDeactivateApi = (
  requestParams: UserDeactivateParameter,
): Promise<UserDeleteApiResponse> => {
  // DELETE
  const response = callApiUtil
    .put<UserDeleteApiResponse>(
      API_URL.USER_DEACTIVATE_STATUS_API,requestParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callUserDeactivateApi;
