import { useNavigate, useLocation } from 'react-router-dom';
import useAccessKey from './useAccessKey';
import { PAGE_PATH_NAME } from '../constants/constants';
import {
  isAccountRegistedPageStateType,
  isPasswordSetCompleteStateType,
  isResetSendMailCompleteStateType,
  isAccountCreateSendMailCompleteStateType,
  isUserDetailStateType,
  // isUserListCompleteStateType,
  isUserAddStateType,
} from '../utils/utility';
import useAccountInfo from './useAccountInfo';

/**
 * 本カスタムフックの返却値
 */
type UseCheckTransitionValue = {
  // 画面遷移を許可するか否か
  allowTransition: () => boolean;
};

/**
 * 画面遷移制御 hooks
 * @returns
 */
const useCheckTransition = (): UseCheckTransitionValue => {
  // navigate(画面遷移)
  const navigate = useNavigate();
  // location(URLパス取得)
  /*eslint-disable*/
  const { pathname, state } = useLocation();
  // アクセスキー hooks
  const { accessKey } = useAccessKey();

  const { accountInfo } = useAccountInfo();
  const allowTransition = (): boolean => {
    // If the access key is not saved in the web storage
    if (!accessKey) {
      // In the case of [Account Registration] screen URL, transition OK
      if (pathname === PAGE_PATH_NAME.ACCOUNT) {
        return true;
      }
      if (pathname === PAGE_PATH_NAME.ACCOUNT_RRGISTED_SEND_MAIL) {
        return true;
      }
      // [Login] In the case of screen URL, transition OK
      if (pathname === PAGE_PATH_NAME.DASHBOARD) {
        return true;
      }
      // [Password Resetting Email Sending] Transition OK in the case of screen URL
      if (pathname === PAGE_PATH_NAME.PASSWORD_RESET_SEND_MAIL) {
        return true;
      }
      // From the [Password Resetting Email Sending] screen [Password Reset Email Sending] Transition to the screen OK
      // (Judgment based on the presence or absence of the state. If there is no State, judge that it is URL directly and transition NG)
      if (
        pathname === PAGE_PATH_NAME.PASSWORD_RESET_SEND_MAIL_COMPLETE &&
        isResetSendMailCompleteStateType(state)
      ) {
        return true;
      }
      // [Password setting] In the case of screen URL, transition OK
      if (pathname === PAGE_PATH_NAME.PASSWORD_SET) {
        return true;
      }
      // From the [Password Settings] screen, transition to the [Password Settings Completed] screen OK (Judgment based on the presence or absence of State. If there is no State, judge the URL directly and transition NG)
      if (
        pathname === PAGE_PATH_NAME.PASSWORD_SET_COMPLETE &&
        isPasswordSetCompleteStateType(state)
      ) {
        return true;
      }
      if (pathname === PAGE_PATH_NAME.PASSWORD_RESET) {
        return true;
      }
      if (
        pathname === PAGE_PATH_NAME.ACCOUNT_RRGISTED_SEND_MAIL_COMPLETE &&
        isAccountCreateSendMailCompleteStateType(state)
      ) {
        return true;
      }
      // Transition to the [Account Registration] screen to [Account registration completion] screen transition OK (Judgment based on the presence or absence of State.
      if (
        pathname === PAGE_PATH_NAME.ACCOUNT_RRGISTED &&
        isAccountRegistedPageStateType(state)
      ) {
        return true;
      }

      // Transition to the [Login] screen (not directly transition URL)
      navigate(PAGE_PATH_NAME.DASHBOARD, { replace: true });

      return false;
    }

    // There is an access key, and if the & & transition destination is the [Translated number] screen, transition to the [Translation] screen
    if (pathname === PAGE_PATH_NAME.TRANS_COUNT_LIST) {
      return false;
    }
    // アクセスキーがある && 遷移先が［翻訳元言語］画面の場合は［翻訳元言語］画面に遷移
    if (pathname === PAGE_PATH_NAME.FROM_LANG_LIST) {
      return false;
    }
    // アクセスキーがある && 遷移先が［翻訳後言語］画面の場合は［翻訳後言語］画面に遷移
    if (pathname === PAGE_PATH_NAME.TO_LANG_LIST) {
      return false;
    }

    // アクセスキーがある && 遷移先が[端末登録解除]画面 && stateが存在する場合は[端末登録解除]画面に遷移
    if (pathname === PAGE_PATH_NAME.DEVICE_DEREGISTER && state) {
      return true;
    }

    // アクセスキーがある && 遷移先が[端末登録解除]画面 && stateが存在する場合は[端末登録解除完了]画面に遷移
    if (pathname === PAGE_PATH_NAME.DEVICE_DEREGISTER_COMPLETE && state) {
      return true;
    }

    // アクセスキーがある && 遷移先が[設定]画面の場合は[設定]画面に遷移
    if (pathname === PAGE_PATH_NAME.SETTING_INFO) {
      return true;
    }

    // アクセスキーがある && 遷移先が[ユーザー管理]画面 && stateが存在する場合は[ユーザー管理]画面に遷移
    if (
      pathname === PAGE_PATH_NAME.USER_LIST
      // &&
      // isUserListCompleteStateType(state)
    ) {
      return true;
    }

    // アクセスキーがある && 遷移先が[ユーザー情報編集]画面 && stateが存在する場合は[ユーザー情報編集]画面に遷移
    if (
      pathname === PAGE_PATH_NAME.USER_DETAIL &&
      isUserDetailStateType(state)
    ) {
      return true;
    }

    // アクセスキーがある && 遷移先が[ユーザー招待メール送信]画面 && stateが存在する場合は[ユーザー招待メール送信]画面に遷移
    if (pathname === PAGE_PATH_NAME.USER_ADD && isUserAddStateType(state)) {
      return true;
    }

    // 基本：アクセスキーがある場合は［端末一覧］画面に遷移
    if (pathname === PAGE_PATH_NAME.DEVICE_LIST) {
      return true;
    }
    // if (pathname === PAGE_PATH_NAME.ADD_GROUP && ) {
    //   return true;
    // }
    if (
      pathname !== PAGE_PATH_NAME.DASHBOARD &&
      !accountInfo.userRoleChangeApiCall
    ) {
      // navigate(PAGE_PATH_NAME.DASHBOARD, { replace: true });
      // // console.log("18");

      return false;
    }

    return true;
  };

  return {
    allowTransition,
  };
};

export default useCheckTransition;
