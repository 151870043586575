/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import { useNavigate } from 'react-router';
import useAccessKey from '../../hooks/useAccessKey';
/* eslint-disable */
const Login = (): JSX.Element => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const { reset } = useAccessKey();


  React.useEffect(() => {
    const handleToken = async () => {
      // void logout({ logoutParams: { returnTo: window.location.origin } })
      if (!isAuthenticated) {
        localStorage.removeItem('OktaToken');
        reset();
        loginWithRedirect();
      } else {
        navigate('/dashboard');
      }
    };

    setTimeout(() => {
    
      if (!isLoading) {
        handleToken();
      }
    }, 0);
  }, [isLoading]);

  return <></>;
};

export default Login;
