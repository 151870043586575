/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, MouseEvent, ReactNode } from 'react';
import * as Material from '@mui/material';
import { makeStyles } from '@mui/styles';

export type TooltipProps = {
  toolTipTitle?: string | number;
  color?: string;
  maxWidth?: number | string;
  lineHeight?: string | number;
  height?: string | number;
  fontSize?: string | number;
  children?: ReactNode;
  toolTipWidth?: number | string;
};

const useStyles = makeStyles(() => ({
  toolTipContent: (props: TooltipProps & { dynamicMaxWidth: string | number }) => ({
    maxWidth:
      typeof props.dynamicMaxWidth === 'number'
        ? `${props.dynamicMaxWidth}px`
        : props.dynamicMaxWidth ?? '',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: props.color ?? '#232F3E',
    fontFamily: "'Noto Sans JP', sans-serif",
    height: props.height ?? '20px',
    lineHeight: props.lineHeight ?? '20px',
    fontSize: props.fontSize ?? '14px',
    paddingRight: '2px',
  }),
}));

const CustomTooltip: React.FC<TooltipProps> = (props) => {
  const [tooltipEnabledGroup, setTooltipEnabledGroup] = useState(false);
  const [dynamicMaxWidth, setDynamicMaxWidth] = useState<string | number>(props.maxWidth || '240px');
  const [toolTipWidth, setTooltipWidth] = useState<string | number>(props.toolTipWidth || '240px');

  useEffect(() => {
    const updateWidths = () => {
      const windowWidth = window.innerWidth;
      const relativeMaxWidth = typeof props?.maxWidth === 'number' ? props?.maxWidth : parseInt(props?.maxWidth || '240', 10);
      const calculatedMaxWidth = windowWidth > 1366 ? `${relativeMaxWidth}px` : windowWidth > 1024 ? `${relativeMaxWidth * 0.8}px` : `${relativeMaxWidth * 0.6}px`;
      const calculatedToolTipWidth = 
        typeof props?.toolTipTitle === 'string' && props?.toolTipTitle?.length > 200
          ? `${windowWidth * 0.4}px`
          : `${props?.toolTipWidth || '240'}px`;
      
      setDynamicMaxWidth(calculatedMaxWidth);
      setTooltipWidth(calculatedToolTipWidth);
    };

    updateWidths();
    window.addEventListener('resize', updateWidths);

    return () => {
      window.removeEventListener('resize', updateWidths);
    };
  }, [props?.maxWidth, props?.toolTipTitle, props?.toolTipWidth]);

  const classes = useStyles({ ...props, dynamicMaxWidth });

  const handleShouldShowTooltip = (event: MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;

    const clone = element.cloneNode(true) as HTMLElement;
    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';
    clone.style.width = 'auto';
    element.parentNode?.appendChild(clone);
    element.style.overflow = 'visible';
    const shouldShow = clone.scrollWidth > element.clientWidth;
    element.parentNode?.removeChild(clone);
    element.style.overflow = 'hidden';

    setTooltipEnabledGroup(shouldShow);
  };

  const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Material.Tooltip
      placement="top"
      title={props.toolTipTitle ?? ''}
      onMouseEnter={handleShouldShowTooltip}
      disableHoverListener={!tooltipEnabledGroup}
      arrow
      sx={{ marginTop: 0 }}
      PopperProps={{
        onClick: handleTooltipClick,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-7, -7],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: toolTipWidth,
            padding: '8px 15px 9px',
            textAlign: 'center',
            fontWeight: 300,
            fontSize: '12px',
            backgroundColor: '#313133',
            lineHeight: '17px',
            whiteSpace: 'normal',
          },
        },
      }}
    >
      {props.children ? (
        React.isValidElement(props.children) ? (
          props.children
        ) : (
          <div className={classes.toolTipContent}>{props.toolTipTitle}</div>
        )
      ) : (
        <div className={classes.toolTipContent}>{props.toolTipTitle}</div>
      )}
    </Material.Tooltip>
  );
};

CustomTooltip.defaultProps = {
  toolTipTitle: '',
  color: '#232F3E',
  maxWidth: '',
  lineHeight: '20px',
  height: '20px',
  fontSize: '13px',
  toolTipWidth: '240px',
};

export default React.memo(CustomTooltip);
