import type {
  DeviceInfoApiRequestParam,
  DeviceInfoApiResponse,
} from '../types/apis/deviceInfoApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';

export const DEVICE_INFO_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 端末未登録
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  // アクセスキー無し(※端末情報取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※端末情報取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※端末情報取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  USER_DEACTIVATED : 'USER_DEACTIVATED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * 端末情報取得エラータイプ
 */
export type DEVICE_INFO_ERROR_TYPE =
  typeof DEVICE_INFO_API_RESULT_CODE[keyof typeof DEVICE_INFO_API_RESULT_CODE];

/**
 * 端末情報取得API呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 端末情報取得APIからのレスポンス
 */
const callDeviceInfoApi = (
  requestParams: DeviceInfoApiRequestParam,
  // accessKey: string,
): Promise<DeviceInfoApiResponse> => {
  const jsonParams = {
    imei: requestParams.imei,
  };

  const response = callApiUtil
    .get<DeviceInfoApiResponse>(
      `${API_URL.DEVICE_INFO_API}?imei=${jsonParams.imei}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callDeviceInfoApi;
