import { Typography } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';

const DisabledInputTypography = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const classes = useStyles();

  // this is use for showing text as like the disabled input field
  // with similar style

  return (
    <Typography className={classes.customRemoteDeviceTextFeldOutline}>
      {children}
    </Typography>
  );
};

export default DisabledInputTypography;
