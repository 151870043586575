/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import { Box, Button, Typography, IconButton, Tooltip } from '@mui/material';
// common styles
import { useStyles } from '../Templates/CommonStyle';
import InputPinCode from '../Atoms/InputPinCode';
import DeleteDialog from '../Atoms/DeleteDialog';
// assets
import { ReactComponent as PlusIcon } from '../../images/icon/plus_blue.svg';
import { ReactComponent as QuestionIcon } from '../../images/icon/Question_light.svg';
// import useRemoteFeatureLimitList from '../../hooks/useRemoteFeatureLimitList';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { FEATURE_PERMISSION } from '../../constants/constants';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { remoteFeatureLimitList } from '../../types/remote/featureLimitList.d';
import useAccountInfo from '../../hooks/useAccountInfo';
import { LOCALE_CODE } from '../../hooks/useLocale';
import CommonSelect from '../Atoms/CommonSelect';
import ToggleSwitch from '../Atoms/ToggleSwitch';
import WifiSettingsCard from './WifiSettingsCard';
import {
  EapMethodEnum,
  ProxyEnum,
  SettingTypeEnum,
  WifiSettingTypeEnum,
} from '../../enums/remoteSettingEnum';
import customStylesMd from '../../utils/customizeReactSelect/customStylesMd';
import type { RemoteFeatureSetupApiRequestParam } from '../../types/apis/remoteFeatureSetupApi.d';
import CustomAnnotationComponent from '../Atoms/CustomAnnotationComponent';

/*eslint-disable*/
type IDParams = {
  groupId: string;
};
type IProps = {
  onClose: () => void;
  formField: FormFieldType;
  featureLimitListData: remoteFeatureLimitList[] | null;
  isGeneralSettingsActive: boolean;
  isCallingRemoteFeatureSetupPostApi: boolean;
  featureSetupResult: any;
  onClickRemoteFeatureSetupPost: (
    requestParams: RemoteFeatureSetupApiRequestParam,
  ) => void;
  setSaveButtonDisable: (value: boolean) => void;
  setIsMenuOpen?: (value: boolean) => void;
};

type NewWifiHandle = {
  submit: () => void;
};

type EapMethod = {
  id: number | null;
  eapType: number;
  peap: PeapMethod | null;
  tls: TlsMethod | null;
};
type PeapMethod = {
  id: number;
  caDomainName: '';
  caCertificateFileName: '';
  caCertificateContent: File | null;
  caUserPassword: '';
  anonymousIdentity: '';
  identity: '';
};
type TlsMethod = {
  id: number;
  caDomainName: '';
  caCertificateFileName: '';
  caCertificateContent: File | null;
  caUserPassword: '';
  identity: '';
  userCertificateFileName: '';
  userCertificateContent: File | null;
  userCertificationExtractionPassword: '';
};
type Wifi_settings = {
  ssid: '';
  password: '' | null;
  securityType: number;
  proxyType: number;
  proxyHostName: '' | undefined;
  proxyPort: '' | null;
  bypassProxy: '' | null;
  pacUrl: '';
  wifiIPSettingType: number;
  ipAddress: '';
  gateway: '' | null;
  networkPrefixLength: '' | null;
  dns1: '' | null;
  dns2: '' | null;
  advanceOpt?: boolean;
  settingType: string;
  deleteFlag: number;
  groupInfoId: number;
  eapMethod: EapMethod | null;
  id: number;
  isS2Series: boolean;
};
type FormFieldType = {
  groupPin: string | null;
  sleepTime: number;
  saveInLocalFlag: number;
  dataDeleteTime: number;
  remoteSettingModeId: number;
  wifiEditable: number;
  mobileDataEditable: number;
  wifiSettings: Wifi_settings[];
};

type FormFieldSubmitType = {
  group_info_id: number;
  group_pin: string | null;
  sleep_time: number;
  save_in_local_flag: number;
  data_delete_time: number;
  wifiEditable: boolean;
  mobileDataEditable: boolean;
  // ave_in_local_flag: number;
  remote_setting_mode_id: number;
  wifi_settings: Wifi_settings[];
};

const stringAppendHandler = (
  mainString: string | null | undefined,
  appendString: string | null | undefined,
): string => {
  mainString = mainString + (mainString !== '' ? ',' : '') + appendString;

  return mainString;
};
const NewWifi = forwardRef<NewWifiHandle, IProps>(({
  formField,
  onClose,
  featureLimitListData,
  isGeneralSettingsActive,
  isCallingRemoteFeatureSetupPostApi,
  featureSetupResult,
  onClickRemoteFeatureSetupPost,
  setSaveButtonDisable,
  setIsMenuOpen
}, ref) => {
  const [customErrors, setCustomErrors] = useState<any>({});
  const [isAddButtonDisable, setAddButtonDisable] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [deleteItems, setDeleteItems] = useState<Wifi_settings[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { t } = useSwitchLocaleLanguage();
  // const { featureLimitListData } = useRemoteFeatureLimitList();
  const { accountInfo } = useAccountInfo();
  const myComponentRef = useRef<HTMLDivElement>(null);

  const sleepTimeOpt = [
    { value: 0, label: t('remoteDevice.sleepTimeOpt.doNotSet') },
    { value: 15, label: t('remoteDevice.sleepTimeOpt.15Seconds') },
    { value: 30, label: t('remoteDevice.sleepTimeOpt.30Seconds') },
    { value: 60, label: t('remoteDevice.sleepTimeOpt.1Minute') },
    { value: 600, label: t('remoteDevice.sleepTimeOpt.10Minutes') },
  ];
  const deleteScreenTime = [
    { value: 0, label: t('remoteDevice.deleteScreenTime.off') },
    { value: 10, label: t('remoteDevice.deleteScreenTime.10Minutes') },
    { value: 30, label: t('remoteDevice.deleteScreenTime.30Minutes') },
    { value: 60, label: t('remoteDevice.deleteScreenTime.1Hour') },
    { value: 720, label: t('remoteDevice.deleteScreenTime.12Hours') },
    { value: 1440, label: t('remoteDevice.deleteScreenTime.24Hours') },
  ];

  const { groupId } = useParams<IDParams>();
  // common style
  const featureLimitOpt = featureLimitListData?.map((value) => ({
    value: value.id,
    label: value.modeName,
  }));
  const classes = useStyles();

  // set temprary value for previously saved eapMethods
  let temporaryFormValue: Wifi_settings[] = [];
  formField?.wifiSettings?.map((value) => {
    const temp = { ...value };
    temporaryFormValue.push(temp);
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    // criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      group_info_id: +groupId!,
      /*eslint-disable*/
      group_pin: formField?.groupPin !== null ? formField?.groupPin : '',
      sleep_time: formField?.sleepTime,
      save_in_local_flag: formField?.saveInLocalFlag,
      data_delete_time: formField?.dataDeleteTime,
      remote_setting_mode_id:
        formField?.remoteSettingModeId !== null
          ? formField?.remoteSettingModeId
          : 1,
      wifiEditable: formField?.wifiEditable === 1 ? true : false,
      mobileDataEditable: formField?.mobileDataEditable === 1 ? true : false,
      wifi_settings:
        // formField?.wifiSettings !== null ? formField?.wifiSettings : [], // actual code.
        formField?.wifiSettings != null ? temporaryFormValue : [], // set temprary value for previously saved eapMethods
    },
  });

  // value definitions
  const SecurityTypeDefinitions = ['None', 'WEP', 'WPA/WPA2 PSK', '802.1xEAP'];
  const ProxyValueDefinitions = ['None', 'Manual', 'Proxy Auto-Config'];
  const IpSettingsValueDefinitions = ['DHCP', 'Static'];

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'wifi_settings', // unique name for your Field Array
  });

  /*eslint-disable*/
  const handleAppend = (value: any) => {
    append(value);

    setTimeout(() => {
      if (myComponentRef.current) {
        myComponentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        });
      }

      setAddButtonDisable(false);
    }, 120);

    checkSaveButtonDisable();
  };

  const onSubmit: SubmitHandler<FormFieldSubmitType> = async (data) => {
    const wifiData = data.wifi_settings
      .filter((x) => !(x.id === 0 && x.settingType === SettingTypeEnum.DELETE))
      .map((x, i) => {
        const { ...rest } = x; // Destructuring to separate cart_file
        const id = 0;
        let output: any = rest;

        if (rest.settingType === SettingTypeEnum.ADD) {
          // Check settingType from rest
          output = { ...rest, id }; // Spread rest and add id
        }

        if (x.securityType === 3) {
          output.password = null;
        }

        if (x.securityType === 3 && x.eapMethod?.eapType === 0) {
          output = {
            ...output,
            eapMethod: {
              eapType: 0,
              peap: {
                id:
                  rest.settingType === SettingTypeEnum.ADD
                    ? null
                    : x.eapMethod?.peap?.id,
                caCertificateContent: x.eapMethod?.peap?.caCertificateContent,
                caCertificateFileName: x.eapMethod?.peap?.caCertificateFileName,
                caDomainName: x.eapMethod?.peap?.caDomainName,
                caUserPassword: x.eapMethod?.peap?.caUserPassword,
                identity: x.eapMethod?.peap?.identity,
                anonymousIdentity: x.eapMethod?.peap?.anonymousIdentity,
                phase2Authentication: 'MSCHAPV2',
              },
              tls: null,
            },
          };
        } else if (x.securityType === 3 && x.eapMethod?.eapType === 1) {
          output = {
            ...output,
            eapMethod: {
              eapType: 1,
              peap: null,
              tls: {
                id:
                  rest.settingType === SettingTypeEnum.ADD
                    ? null
                    : x.eapMethod?.tls?.id,
                caCertificateContent: x.eapMethod?.tls?.caCertificateContent,
                caCertificateFileName: x.eapMethod?.tls?.caCertificateFileName,
                caDomainName: x.eapMethod?.tls?.caDomainName,
                caUserPassword: x.eapMethod?.tls?.caUserPassword,
                identity: x.eapMethod?.tls?.identity,
                userCertificateFileName:
                  x.eapMethod?.tls?.userCertificateFileName,
                userCertificateContent:
                  x.eapMethod?.tls?.userCertificateContent,
                userCertificationExtractionPassword:
                  x.eapMethod?.tls?.userCertificationExtractionPassword,
              },
            },
          };
        } else if (x.securityType !== 3) {
          output = {
            ...output,
            eapMethod: null,
          };
        }

        return output; // Return rest
      });

    const payload = {
      groupId: +groupId!,
      commonSettings:
        (data.group_pin === ''
          ? formField.groupPin !== ''
          : data.group_pin !== formField.groupPin) ||
        data.sleep_time !== formField.sleepTime ||
        data.data_delete_time !== formField.dataDeleteTime ||
        (saveTranslationHistoryToggle === true ? 1 : 0) !==
          formField.saveInLocalFlag ||
        (editableMobileDataToggle === true ? 1 : 0) !==
          formField.mobileDataEditable ||
        (editableWifiSettingToggle === true ? 1 : 0) !==
          formField.wifiEditable ||
        (formField?.wifiSettings === null
          ? data.wifi_settings.length !== 0
          : !(await arraysEqual(
              control._formValues.wifi_settings
                .filter(
                  (value: any) =>
                    value.settingType !== SettingTypeEnum.DELETE ||
                    value.id !== 0,
                )
                .map(
                  ({
                    advanceOpt,
                    isS2Series,
                    phase2Auth,
                    settingType,
                    ...rest
                  }: {
                    advanceOpt: boolean;
                    isS2Series: boolean;
                    phase2Auth: string;
                    settingType: string;
                    [key: string]: any;
                  }) => rest,
                ),
              formField?.wifiSettings?.map(
                ({
                  settingType,
                  ...rest
                }: {
                  settingType: string;
                  [key: string]: any;
                }) => rest,
              ),
            )))
          ? {
              groupPin: data.group_pin === '' ? null : data.group_pin,
              sleepTime: data.sleep_time,
              saveInLocalFlag: saveTranslationHistoryToggle ? 1 : 0,
              dataDeleteTime: data.data_delete_time,
              mobileDataEditable: editableMobileDataToggle === true ? 1 : 0,
              wifiEditable: editableWifiSettingToggle === true ? 1 : 0,
              wifiSettings: wifiData.concat(deleteItems),
            }
          : null,

      remoteSettingModeId:
        data.remote_setting_mode_id === formField.remoteSettingModeId
          ? null
          : formField.remoteSettingModeId === null &&
            data.remote_setting_mode_id === 1
          ? null
          : data.remote_setting_mode_id,
    };

    // GA tag starts
    // this GA event will trigger only for 6 digit pincode
    if (payload.commonSettings?.groupPin?.toString().length === 6) {
      // GA tag
      sendGAEvent('edit_txt_num', 'txt_name', 'input_pin_code');
      sendGAEvent(
        'edit_txt_num',
        'input_pin_code_value',
        payload.commonSettings.groupPin.toString(),
      );
    }

    if (payload.commonSettings?.sleepTime) {
      // GA tag
      sendGAEvent('select_list', 'txt_name', 'autolock_screen');
      sendGAEvent(
        'select_list',
        'autolock_screen_value',
        payload.commonSettings.sleepTime.toString(),
      );
    }

    if (payload.commonSettings?.dataDeleteTime) {
      // GA tag
      sendGAEvent('select_list', 'txt_name', 'auto_delete_time');
      sendGAEvent(
        'select_list',
        'auto_delete_time_value',
        payload.commonSettings.dataDeleteTime.toString(),
      );
    }

    if (payload.remoteSettingModeId !== null && featureLimitListData) {
      // GA tag
      sendGAEvent('select_list', 'txt_name', 'feature_limit');
      sendGAEvent(
        'select_list',
        'feature_limit_value',
        featureLimitListData[payload.remoteSettingModeId]?.modeName,
      );
    }

    if (payload.commonSettings?.wifiSettings?.length) {
      const wifiSettings = payload.commonSettings.wifiSettings;

      let proxyHostNameString = '';
      let proxyPortString = '';
      let bypassProxyForString = '';
      let pacUrlString = '';
      let ipAddressString = '';
      let gateWayString = '';
      let networkPrefixLengthString = '';
      let dns1String = '';
      let dns2String = '';
      let securityString = '';
      let proxyTypeString = '';
      let ipSettingString = '';

      wifiSettings.forEach((obj) => {
        if (obj.proxyHostName !== '') {
          proxyHostNameString = stringAppendHandler(
            proxyHostNameString,
            obj.proxyHostName,
          );
        }

        if (obj.proxyPort !== null) {
          proxyPortString = stringAppendHandler(
            proxyPortString,
            obj.proxyPort.toString(),
          );
        }

        if (obj.bypassProxy !== '') {
          bypassProxyForString = stringAppendHandler(
            bypassProxyForString,
            obj.bypassProxy,
          );
        }

        if (obj.pacUrl !== '') {
          pacUrlString = stringAppendHandler(pacUrlString, obj.pacUrl);
        }

        if (obj.ipAddress !== '') {
          ipAddressString = stringAppendHandler(ipAddressString, obj.ipAddress);
        }

        if (obj.gateway !== '') {
          gateWayString = stringAppendHandler(gateWayString, obj.gateway);
        }

        if (obj.networkPrefixLength !== null) {
          networkPrefixLengthString = stringAppendHandler(
            networkPrefixLengthString,
            obj.networkPrefixLength.toString(),
          );
        }

        if (obj.dns1 !== '') {
          dns1String = stringAppendHandler(dns1String, obj.dns1);
        }
        if (obj.dns2 !== '') {
          dns2String = stringAppendHandler(dns2String, obj.dns2);
        }

        if (obj.securityType !== null) {
          securityString = stringAppendHandler(
            securityString,
            SecurityTypeDefinitions[obj.securityType],
          );
        }

        if (obj.proxyType !== null) {
          proxyTypeString = stringAppendHandler(
            proxyTypeString,
            ProxyValueDefinitions[obj.proxyType],
          );
        }

        if (obj.wifiIPSettingType !== null) {
          ipSettingString = stringAppendHandler(
            ipSettingString,
            IpSettingsValueDefinitions[obj.wifiIPSettingType],
          );
        }
      });

      if (proxyHostNameString !== '') {
        // GA tag
        sendGAEvent('edit_txt_char', 'txt_name', 'proxy_host_name');
        sendGAEvent(
          'edit_txt_char',
          'proxy_host_name_value',
          proxyHostNameString,
        );
      }

      if (proxyPortString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'proxy_port');
        sendGAEvent('edit_txt_num', 'proxy_port_value', proxyPortString);
      }

      if (bypassProxyForString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'bypass_proxy_for');
        sendGAEvent(
          'edit_txt_num',
          'bypass_proxy_for_value',
          bypassProxyForString,
        );
      }

      if (pacUrlString !== '') {
        // GA tag
        sendGAEvent('edit_txt_char', 'txt_name', 'pacurl');
        sendGAEvent('edit_txt_char', 'pacurl_value', pacUrlString);
      }

      if (ipAddressString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'ip_address');
        sendGAEvent('edit_txt_num', 'ip_address_value', ipAddressString);
      }

      if (gateWayString !== '') {
        // GA tag
        sendGAEvent('edit_txt_char', 'txt_name', 'gateway');
        sendGAEvent('edit_txt_char', 'gateway_value', gateWayString);
      }

      if (networkPrefixLengthString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'network_prefix_length');
        sendGAEvent(
          'edit_txt_num',
          'network_prefix_length_value',
          networkPrefixLengthString,
        );
      }

      if (dns1String !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'dns1');
        sendGAEvent('edit_txt_num', 'dns2_value', dns1String);
      }
      if (dns2String !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'dns2');
        sendGAEvent('edit_txt_num', 'dns1_value', dns2String);
      }

      if (securityString !== '') {
        // GA tag
        sendGAEvent('select_list', 'txt_name', 'security');
        sendGAEvent('select_list', 'security_value', securityString);
      }

      if (proxyTypeString !== '') {
        // GA tag
        sendGAEvent('select_list', 'txt_name', 'proxy');
        sendGAEvent('select_list', 'proxy_value', proxyTypeString);
      }

      if (ipSettingString !== '') {
        // GA tag
        sendGAEvent('select_list', 'txt_name', 'ip_setting');
        sendGAEvent('select_list', 'ip_setting_value', ipSettingString);
      }
    }

    // GA tag ends

    // eslint-disable-next-line no-console
    // @ts-ignore:next-line
    onClickRemoteFeatureSetupPost(payload);
  };

  useEffect(() => {
    if (!isCallingRemoteFeatureSetupPostApi && featureSetupResult === 'OK') {
      onClose();
    }
  }, [featureSetupResult]);

  // for react select medium start
  // const customStylesMd = {
  //   control: (provided: any, state: { isFocused: any }) => ({
  //     ...provided,
  //     background: '#fff',
  //     minHeight: '38px',
  //     height: '38px',
  //     borderRadius: '6px',
  //     boxShadow: state.isFocused ? '0px 0px 1px #939598' : null,
  //     border: state.isFocused ? '1px solid #939598' : '1px solid #d9d9d9',
  //     fontSize: '14px',
  //     cursor: 'pointer',
  //     '&:hover': {
  //       borderColor: '#939598',
  //     },
  //     '&:focus': {
  //       borderColor: '#939598',
  //     },
  //   }),

  //   valueContainer: (provided: any, state: any) => ({
  //     ...provided,
  //     height: '38px',
  //     padding: '0 8px',
  //     marginTop: '-1px',
  //   }),

  //   input: (provided: any, state: any) => ({
  //     ...provided,
  //     margin: '0px',
  //   }),
  //   indicatorSeparator: (state: any) => ({
  //     display: 'none',
  //   }),
  //   indicatorsContainer: (provided: any, state: any) => ({
  //     ...provided,
  //     height: '38px',
  //     marginTop: '-1px',
  //   }),
  //   menu: (provided: any, state: any) => ({
  //     ...provided,
  //     borderRadius: '6px',
  //   }),
  //   option: (provided: any, state: any) => ({
  //     ...provided,
  //     height: '37px',
  //     backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
  //     // color: state.isSelected ? '#313133' : '#939598',
  //     color: '#313133',
  //     ':active': {
  //       backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
  //     },
  //     ':first-child': {
  //       borderTopRightRadius: '6px',
  //       borderTopLeftRadius: '6px',
  //     },
  //     ':last-child': {
  //       borderBottomRightRadius: '6px',
  //       borderBottomLeftRadius: '6px',
  //     },
  //   }),
  // };
  // for react select medium end
  // for react select sm start

  useEffect(() => {
    fields?.map((item, i) => {
      if (item?.settingType !== SettingTypeEnum.ADD) {
        setValue(`wifi_settings.${i}.settingType`, SettingTypeEnum.EDIT);

        if (item.securityType !== 3) {
          setValue(`wifi_settings.${i}.eapMethod.eapType`, 0);
        }
      }

      if (item.securityType === 3) {
        setValue(`wifi_settings.${i}.isS2Series`, true);
      } else {
        setValue(`wifi_settings.${i}.isS2Series`, false);
      }
    });
  }, []);

  const deleteHandler = (index: number) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  const deleteHandleMOdal = (index: number) => {
    let fieldValue = fields[index];
    setValue(`wifi_settings.${index}.settingType`, SettingTypeEnum.DELETE);
    setValue(`wifi_settings.${index}.deleteFlag`, 1);
    if (fieldValue.id > 0) {
      fieldValue.settingType = SettingTypeEnum.DELETE;
      fieldValue.deleteFlag = 1;
      setDeleteItems([...deleteItems, fields[index]]);
    }
    // remove(index);
    setDeleteDialogOpen(false);
  };

  // Comparing wifiSetting Changes with DB data
  const objectsEqual = async (currentData: any, actualData: any) => {
    try {
      const wifiIPSettingType = currentData.wifiIPSettingType ?? 0;
      const proxyType = currentData.proxyType ?? 0;
      const securityType = currentData.securityType ?? 0;

      const currentDataKeys = Object.keys(currentData);
      const actualDataKeys = Object.keys(actualData);

      // Properties to ignore based on proxyType/wifiIPSettings
      const propertiesToIgnore: any = [];

      // Properties to ignore based on proxyBypassProxyType
      if (proxyType === 0)
        propertiesToIgnore.push(
          ProxyEnum.ProxyHostName,
          ProxyEnum.ProxyPort,
          ProxyEnum.BypassProxy,
          ProxyEnum.PacUrl,
        );
      if (proxyType === 1) propertiesToIgnore.push(ProxyEnum.PacUrl);
      if (proxyType === 2)
        propertiesToIgnore.push(
          ProxyEnum.ProxyHostName,
          ProxyEnum.ProxyPort,
          ProxyEnum.BypassProxy,
        );

      // Properties to ignore based on wifiIPSettingType
      if (wifiIPSettingType === 0)
        propertiesToIgnore.push(
          WifiSettingTypeEnum.IpAddress,
          WifiSettingTypeEnum.Gateway,
          WifiSettingTypeEnum.NetworkPrefixLength,
          WifiSettingTypeEnum.DNS1,
          WifiSettingTypeEnum.DNS2,
        );

      if (
        !currentData[EapMethodEnum.AnonymousIdentity] &&
        !actualData[EapMethodEnum.AnonymousIdentity]
      ) {
        propertiesToIgnore.push(EapMethodEnum.AnonymousIdentity);
      }

      if (securityType === 0) {
        propertiesToIgnore.push(EapMethodEnum.EapMethod);
      }

      if (currentData[EapMethodEnum.PEAP] && !actualData[EapMethodEnum.PEAP])
        propertiesToIgnore.push(EapMethodEnum.PEAP);

      if (currentData[EapMethodEnum.TLS] && !actualData[EapMethodEnum.TLS])
        propertiesToIgnore.push(EapMethodEnum.TLS);

      const filterCurrentDataKeys = currentDataKeys.filter(
        (key) => !propertiesToIgnore.includes(key),
      );
      const filterActualDataKeys = actualDataKeys.filter(
        (key) => !propertiesToIgnore.includes(key),
      );

      if (filterCurrentDataKeys.length !== filterActualDataKeys.length)
        return false;

      for (let key of filterCurrentDataKeys) {
        // as eapMethod is an object it should check all the properties of it
        // peap and tls inside eapMethod is also an object
        if (key === EapMethodEnum.EapMethod) {
          if (actualData[key] !== null) {
            const value = await objectsEqual(currentData[key], actualData[key]);
            if (!value) {
              return false;
            }
          }
        }

        // as peap is an object it should check all the properties of it
        if (
          key === EapMethodEnum.PEAP &&
          currentData[key] &&
          currentData[EapMethodEnum.EapType] === 0
        ) {
          const value = await objectsEqual(currentData[key], actualData[key]);
          if (!value) {
            return false;
          }
        }

        // as tls is an object it should check all the properties of it
        if (
          key === EapMethodEnum.TLS &&
          currentData[key] &&
          currentData[EapMethodEnum.EapType] === 1
        ) {
          const value = await objectsEqual(currentData[key], actualData[key]);
          if (!value) {
            return false;
          }
        }

        // as eapMethod, peap, tls are objects they shouldn't check here
        if (
          key !== EapMethodEnum.EapMethod &&
          key !== EapMethodEnum.PEAP &&
          key !== EapMethodEnum.TLS
        ) {
          if (currentData[key] !== actualData[key]) {
            return false;
          }
        }
      }

      return true;
    } catch (err) {
      return false;
    }
  };

  const arraysEqual = async (currentData: any[], actualData: any[]) => {
    if (currentData?.length !== actualData?.length) {
      return false;
    }

    for (let i = 0; i < currentData.length; i++) {
      const isEqual = await objectsEqual(currentData[i], actualData[i]);
      if (!isEqual) {
        return false;
      }
    }
    return true;
  };

  const EditAccessForDataProtection =
    accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLDATAPROTECTION?.includes(
      FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLDATAPROTECTION.EDIT,
    );

  const EditAccessForFeatureLimit =
    accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLFEATURELIMIT?.includes(
      FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLFEATURELIMIT.EDIT,
    );

  const EditAccessForWifi =
    accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI.includes(
      FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.EDIT,
    );

  // Editable Toggle button handling
  const [saveTranslationHistoryToggle, setSaveTranslationHistoryToggle] =
    useState<boolean>(formField?.saveInLocalFlag === 1 ? true : false);
  const [editableMobileDataToggle, setEditableMobileDataToggle] =
    useState<boolean>(formField?.mobileDataEditable === 1 ? true : false);
  const [editableWifiSettingToggle, setEditableWifiSettingToggle] =
    useState<boolean>(formField?.wifiEditable === 1 ? true : false);
  ``;
  const [autoUpdateAtNightToggle, setAutoUpdateAtNightToggleToggle] =
    useState<boolean>(false);

  const onClickSaveTranslationHistoryToggle = () => {
    setSaveTranslationHistoryToggle(!saveTranslationHistoryToggle);
  };
  const onClickEditableMobileData = () => {
    setEditableMobileDataToggle(!editableMobileDataToggle);
  };
  const onClickEditableWifiSetting = () => {
    setEditableWifiSettingToggle(!editableWifiSettingToggle);
  };
  const onClickAutoUpdateAtNightSetting = () => {
    setAutoUpdateAtNightToggleToggle(!autoUpdateAtNightToggle);
  };

  useEffect(() => {
    checkSaveButtonDisable();
  }, [
    saveTranslationHistoryToggle,
    editableMobileDataToggle,
    editableWifiSettingToggle,
    autoUpdateAtNightToggle,
  ]);

  const validateInput = (name: string, value: string, fieldRules: any) => {
    if (!fieldRules) return '';

    for (const ruleKey in fieldRules) {
      const rule = fieldRules[ruleKey];
      switch (ruleKey) {
        case 'required':
          if (rule.value && !value) {
            let error = customErrors;
            error[name] = rule.message;
            setCustomErrors(error);

            return;
          }
          break;
        case 'minLength':
          if (value?.length < rule.value) {
            let error = customErrors;
            error[name] = rule.message;
            setCustomErrors(error);

            return;
          }
          break;
        case 'maxLength':
          if (value?.length > rule.value) {
            let error = customErrors;
            error[name] = rule.message;
            setCustomErrors(error);

            return;
          }
          break;
        case 'pattern':
          if (value && !RegExp(rule.value).test(value)) {
            let error = customErrors;
            error[name] = rule.message;
            setCustomErrors(error);

            return;
          }
          break;
        default:
          break;
      }
    }

    let error = customErrors;
    delete error[name];
    setCustomErrors(error);

    return;
  };

  const checkSaveButtonDisable = async () => {
    if (Object.keys(customErrors).length > 0) {
      setSaveButtonDisable(true);
      return;
    }

    if (formField?.groupPin === null) {
      formField.groupPin = '';
    }

    if (formField?.dataDeleteTime === null) {
      formField.dataDeleteTime = 0;
    }

    if (formField?.sleepTime === null) {
      formField.sleepTime = 0;
    }

    if (formField?.remoteSettingModeId === null) {
      formField.remoteSettingModeId = 1;
    }

    // if (formField?.mobileDataEditable === undefined) {
    //   formField.mobileDataEditable = 0;
    // }

    // if (formField?.wifiEditable === undefined) {
    //   formField.wifiEditable = 0;
    // }

    try {
      if (
        (editableMobileDataToggle === true ? 1 : 0) ===
          formField.mobileDataEditable &&
        (editableWifiSettingToggle === true ? 1 : 0) ===
          formField.wifiEditable &&
        autoUpdateAtNightToggle === false &&
        control._formValues.group_pin === formField.groupPin &&
        control._formValues.sleep_time === formField.sleepTime &&
        (saveTranslationHistoryToggle === true ? 1 : 0) ===
          (formField.saveInLocalFlag ? formField.saveInLocalFlag : 0) &&
        control._formValues.data_delete_time === formField.dataDeleteTime &&
        control._formValues.remote_setting_mode_id ===
          formField.remoteSettingModeId
      ) {
        let flag: boolean = false;

        control._formValues.wifi_settings
          .filter(
            (value: any) =>
              value.settingType !== SettingTypeEnum.DELETE || value.id !== 0,
          )
          .map((item: any) => {
            // check ssid & password
            if (item.ssid === '') {
              setSaveButtonDisable(true);
              flag = true;
            }

            if (item.password === '' && item.securityType !== 3) {
              setSaveButtonDisable(true);
              flag = true;
            }

            // check security type
            if (item.securityType === 3) {
              // check security type peap
              if (
                item.eapMethod.eapType === 0 &&
                item.eapMethod.peap !== null
              ) {
                if (item.eapMethod.peap.caDomainName === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }

                if (item.eapMethod.peap.caCertificateFileName === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }

                if (item.eapMethod.peap.caUserPassword === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }

                if (item.eapMethod.peap.identity === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }
              }

              // check security type tls
              if (item.eapMethod.eapType === 1 && item.eapMethod.tls !== null) {
                if (item.eapMethod.tls.caDomainName === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }

                if (item.eapMethod.tls.caCertificateFileName === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }

                if (item.eapMethod.tls.identity === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }

                if (item.eapMethod.tls.userCertificateFileName === '') {
                  setSaveButtonDisable(true);
                  flag = true;
                }

                if (
                  item.eapMethod.tls.userCertificationExtractionPassword === ''
                ) {
                  setSaveButtonDisable(true);
                  flag = true;
                }
              }
            }

            // check proxyType manual
            if (item.proxyType === 1) {
              if (item.proxyHostName === '') {
                setSaveButtonDisable(true);
                flag = true;
              }

              if (item.proxyPort === '') {
                setSaveButtonDisable(true);
                flag = true;
              }

              if (item.bypassProxy === '') {
                setSaveButtonDisable(true);
                flag = true;
              }
            }

            // check proxyType proxy-auto-config
            if (item.proxyType === 2) {
              if (item.pacUrl === '') {
                setSaveButtonDisable(true);
                flag = true;
              }
            }

            // check ip settings static
            if (item.wifiIPSettingType === 1) {
              if (item.ipAddress === '') {
                setSaveButtonDisable(true);
                flag = true;
              }

              if (item.gateway === '') {
                setSaveButtonDisable(true);
                flag = true;
              }

              if (item.networkPrefixLength === '') {
                setSaveButtonDisable(true);
                flag = true;
              }

              if (item.dns1 === '') {
                setSaveButtonDisable(true);
                flag = true;
              }

              if (item.dns2 === '') {
                setSaveButtonDisable(true);
                flag = true;
              }
            }
          });

        if (flag) {
          return;
        }

        if (
          control._formValues.wifi_settings.length === 0 ||
          (await arraysEqual(
            control._formValues.wifi_settings
              .filter(
                (value: any) =>
                  value.settingType !== SettingTypeEnum.DELETE ||
                  value.id !== 0,
              )
              .map(
                ({
                  advanceOpt,
                  isS2Series,
                  phase2Auth,
                  settingType,
                  ...rest
                }: {
                  advanceOpt: boolean;
                  isS2Series: boolean;
                  phase2Auth: string;
                  settingType: string;
                  [key: string]: any;
                }) => rest,
              ),
            formField?.wifiSettings?.map(
              ({
                settingType,
                ...rest
              }: {
                settingType: string;
                [key: string]: any;
              }) => rest,
            ),
          ))
        ) {
          setSaveButtonDisable(true);
        } else {
          setSaveButtonDisable(false);
        }
      } else {
        setSaveButtonDisable(false);
      }
    } catch (error) {}
  };


  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit(onSubmit)();
    }
  }));

  return (
    <div ref={myComponentRef}>
      <form>
        {/* Remote Feature Items */}
        {isGeneralSettingsActive && (
          <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                columnGap: '30px',
                rowGap: '20px',
                marginBottom: '16px',
              }}
              className="remove-device-setup-protection-input-form"
            >
              {/* Group Pin Code */}
              {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLDATAPROTECTION?.includes(
                FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLDATAPROTECTION
                  .VIEWDETAILS,
              ) && (
                <>
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.pinCode')}
                    </Typography>
                    <Controller
                      control={control}
                      name="group_pin"
                      rules={{
                        pattern: {
                          value: /^(|[0-9]{6})$/,
                          message: 'PIN Code pattern',
                        },
                        maxLength: { value: 6, message: 'Max Length' },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputPinCode
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            validateInput('group_pin', e.toString(), {
                              pattern: {
                                value: /^(|[0-9]{6})$/,
                                message: 'PIN Code pattern',
                              },
                              maxLength: { value: 6, message: 'Max Length' },
                            });
                            checkSaveButtonDisable();
                          }}
                          defaultValue="group_pin"
                          size="medium"
                          color={errors.group_pin ? 'error' : 'primary'}
                          inputProps={{ maxLength: 6 }}
                          disabled={!EditAccessForDataProtection}
                        />
                      )}

                      /*eslint-disable*/
                    />
                    {errors?.group_pin && (
                      <Box
                        sx={{
                          bottom: '45px !important',
                        }}
                        className={classes.newWifiErrorCard}
                      >
                        <p style={{ padding: '0', margin: '0' }}>
                          {t('remoteDevice.message.pinValidation')}
                        </p>
                      </Box>
                    )}
                    <p
                      className={classes.inputText}
                      style={{ position: 'static' }}
                    >
                      {t('remoteDevice.message.digit')}
                    </p>
                  </Box>
                  {/* Sleep Time Field */}
                  <Box>
                    <label
                      style={{
                        marginTop:
                          accountInfo.locale === LOCALE_CODE.FR ? '-15px' : '',
                      }}
                      className={classes.customInputLabel}
                    >
                      {t('remoteDevice.label.autoLock')}
                    </label>
                    <CommonSelect
                      ControllerName={'sleep_time'}
                      control={control}
                      options={sleepTimeOpt}
                      isSearchable={false}
                      className={classes.reactSelect}
                      classNamePrefix="react-select"
                      styles={customStylesMd()}
                      isDisabled={!EditAccessForDataProtection}
                      onChangeValue={checkSaveButtonDisable}
                    />
                  </Box>

                  {/* Auto Delete Field */}

                  <Box>
                    <label
                      style={{
                        marginTop:
                          accountInfo.locale === LOCALE_CODE.DE ||
                          accountInfo.locale === LOCALE_CODE.FR
                            ? '-15px'
                            : '',
                      }}
                      className={classes.customInputLabel}
                    >
                      {t('remoteDevice.label.autoDelete')}
                    </label>
                    <CommonSelect
                      ControllerName={'data_delete_time'}
                      control={control}
                      options={deleteScreenTime}
                      isSearchable={false}
                      className={classes.reactSelect}
                      classNamePrefix="react-select"
                      styles={customStylesMd()}
                      isDisabled={false}
                      onChangeValue={checkSaveButtonDisable}
                    ></CommonSelect>
                  </Box>
                </>
              )}
            </Box>

            {/* Feature Limit Field */}
            {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLFEATURELIMIT?.includes(
              FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLFEATURELIMIT
                .VIEWDETAILS,
            ) && (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    fontSize: '14px',
                    color: '#313133',
                    letterSpacing: '0.09px',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px',
                  }}
                >
                  {t('remoteDevice.label.featureLimit')}
                  <Tooltip
                    placement="top"
                    title={t('remoteDevice.tooltip.feature')}
                    arrow
                    sx={{ marginTop: 0 }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-7, -7],
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          minWidth: '126px',
                          height: '26px',
                          textAlign: 'center',
                          fontWeight: 300,
                          fontSize: '12px',
                          backgroundColor: '#313133',
                          lineHeight: 1.35,
                        },
                      },
                    }}
                  >
                    <a
                      href="https://www.pocketalk.com/pocketalk-faq/#reamaze#0#/kb/pocketalk-ventana/feature-limit"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        padding: '0',
                        marginLeft: '7px',
                        display: 'flex',
                      }}
                    >
                      <QuestionIcon className={classes.tooltipIcon} />
                    </a>
                  </Tooltip>
                </Typography>

                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: '30px',
                    rowGap: '20px',
                    marginBottom: '20px',
                  }}
                  className="remove-device-setup-protection-input-form"
                >
                  <CommonSelect
                    ControllerName={'remote_setting_mode_id'}
                    control={control}
                    options={featureLimitOpt}
                    isSearchable={false}
                    className={classes.reactSelect}
                    classNamePrefix="react-select"
                    styles={customStylesMd()}
                    isDisabled={!EditAccessForFeatureLimit}
                    onChangeValue={checkSaveButtonDisable}
                  ></CommonSelect>
                </Box>
              </Box>
            )}
          </>
        )}

        {!isGeneralSettingsActive && (
          <>
            <Box sx={{
              paddingBottom: '20px',
              paddingTop: '8px',
            }}>
              <CustomAnnotationComponent
                text= {t('remoteDevice.label.annotationText')}
                type="Moderate"
              />
            </Box>
            <Typography
              sx={{
                fontWeight: 'bold',
                lineHeight: '20px',
                fontSize: '14px',
                color: '#313133',
                letterSpacing: '0.09px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              {t('remoteDevice.label.featureLimit')}
              <Tooltip
                placement="top"
                title={t('remoteDevice.tooltip.feature')}
                arrow
                sx={{ marginTop: 0 }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-7, -7],
                      },
                    },
                  ],
                }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      minWidth: '126px',
                      height: '26px',
                      textAlign: 'center',
                      fontWeight: 300,
                      fontSize: '12px',
                      backgroundColor: '#313133',
                      lineHeight: 1.35,
                    },
                  },
                }}
              >
                <a
                  href="https://www.pocketalk.com/pocketalk-faq/#reamaze#0#/kb/pocketalk-ventana/feature-limit"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    padding: '0',
                    marginLeft: '7px',
                    display: 'flex',
                  }}
                >
                  <QuestionIcon className={classes.tooltipIcon} />
                </a>
              </Tooltip>
            </Typography>
            <Box sx={{ marginBottom: '16px', display: 'flex', gap: '15px' }}>
              <Box sx={{ position: 'relative', width: '50%' }}>
                <Typography
                  className={classes.customInputLabel}
                  sx={{ width: '100%' }}
                >
                  {t('remoteDevice.label.editableMobileDataSetting')}
                </Typography>
                <Button
                  sx={{ padding: '0px', minWidth: '0px' }}
                  onClick={onClickEditableMobileData}
                  // disabled={isCallingApi}
                  disableRipple
                >
                  <ToggleSwitch switchStatus={editableMobileDataToggle} isBig />
                </Button>

                {/* <p className={classes.toggleNoteText}>
                {t('remoteDevice.message.editableMobileDataSettingMessage')}
              </p> */}
              </Box>
              <Box sx={{ position: 'relative', width: '50%' }}>
                <Typography
                  className={classes.customInputLabel}
                  sx={{ width: '100%' }}
                >
                  {t('remoteDevice.label.editableWifiDataSetting')}
                </Typography>
                <Button
                  sx={{ padding: '0px', minWidth: '0px' }}
                  onClick={onClickEditableWifiSetting}
                  // disabled={isCallingApi}
                  disableRipple
                >
                  <ToggleSwitch
                    switchStatus={editableWifiSettingToggle}
                    isBig
                  />
                </Button>

                {/* <p className={classes.toggleNoteText}>
                {t('remoteDevice.message.editableWifiDataSettingMessage')}
              </p> */}
              </Box>
            </Box>
          </>
        )}

        {/* device update */}
        {/* <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              lineHeight: '20px',
              fontSize: '14px',
              color: '#313133',
              letterSpacing: '0.09px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '12px',
            }}
          >
            {t('remoteDevice.label.deviceUpdate')}
          </Typography>
          <Box sx={{ position: 'relative', width: '50%' }}>
            <Typography
              className={classes.customInputLabel}
              sx={{ width: '100%' }}
            >
              {t('remoteDevice.label.autoUpdateAtNight')}
            </Typography>
            <Button
              sx={{ padding: '0px', minWidth: '0px' }}
              onClick={onClickAutoUpdateAtNightSetting}
              // disabled={isCallingApi}
              disableRipple
            >
              <ToggleSwitch switchStatus={autoUpdateAtNightToggle} isBig />
            </Button>

            <p
              className={classes.inputText}
              style={{maxWidth: '220px', minHeight: '46px'}}
            >
              {t('remoteDevice.message.autoUpdateAtNightMessage')}
            </p>
          </Box>
        </Box> */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
            gap: '5px',
          }}
        >
          {/* Wi-Fi Settings Text */}
          {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI?.includes(
            FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.VIEWDETAILS,
          ) && (
            <Typography
              sx={{
                fontWeight: 'bold',
                lineHeight: '20px',
                fontSize: '14px',
                color: '#313133',
                display: 'flex',
              }}
            >
              {`${
                isGeneralSettingsActive
                  ? `${t('remoteDevice.label.wifiSet')}`
                  : `${t('remoteDevice.label.wifiSet')} (${t(
                      'remoteDevice.label.security',
                    )}: 802.1xEAP)`
              }`}
              {!isGeneralSettingsActive && (
                <Tooltip
                  placement="top"
                  title={t('remoteDevice.tooltip.wifiSettings')}
                  arrow
                  sx={{ marginTop: 0 }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-7, -7],
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        minWidth: '126px',
                        height: '60px',
                        fontWeight: 300,
                        fontSize: '12px',
                        backgroundColor: '#313133',
                        lineHeight: 1.35,
                      },
                    },
                  }}
                >
                  <a
                    href="https://www.pocketalk.com/pocketalk-faq/#reamaze#0#/kb/pocketalk-ventana/feature-limit"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginTop: '2px',
                      padding: '0',
                      marginLeft: '7px',
                      display: 'flex',
                    }}
                  >
                    <QuestionIcon className={classes.tooltipIcon} />
                  </a>
                </Tooltip>
              )}
            </Typography>
          )}
          {/* Add wifi link */}
          {EditAccessForWifi && (
            <IconButton
              type="button"
              aria-label="add wifi"
              sx={{
                p: '0px',
                borderRadius: '30%',
                color: '#009DE1',
                ':hover': {
                  backgroundColor: 'transparent',
                  color: '#007EB4',
                  '& svg': {
                    '& line': {
                      stroke: '#007EB4',
                    },
                  },
                },
              }}
              disabled={!EditAccessForWifi || isAddButtonDisable}
              disableRipple
              onClick={() => {
                setAddButtonDisable(true);
                handleAppend({
                  ssid: '',
                  password: '',
                  securityType: isGeneralSettingsActive ? 0 : 3,
                  proxyType: 0,
                  proxyHostName: '',
                  proxyPort: '',
                  bypassProxy: '',
                  pacUrl: '',
                  wifiIPSettingType: 0,
                  ipAddress: '',
                  gateway: '',
                  networkPrefixLength: '',
                  dns1: '',
                  dns2: '',
                  advanceOpt: false,
                  isS2Series: !isGeneralSettingsActive,
                  settingType: SettingTypeEnum.ADD,
                  eapMethod: {
                    eapType: 0,
                    peap: null,
                    tls: null,
                  },
                  caDomainName: '',
                  caUserPassword: '',
                  identity: '',
                  anonymousIdentity: '',
                  deleteFlag: 0,
                  groupInfoId: +groupId!,
                  id: 0,
                });

                // GA tag
                sendGAEvent('press_btn', 'btn_name', 'add_new_wifi');
              }}
            >
              <PlusIcon className={classes.addIcon} />
              <Typography
                fontSize="14px"
                lineHeight="20px"
                height="20px"
                pl="3px"
                sx={{
                  letterSpacing: '0.09px',
                }}
              >
                {t('remoteDevice.button.newWifi')}
              </Typography>
            </IconButton>
          )}
        </Box>
        {/* Wifi Setting Box Start */}
        <Box
          className={classes.wifiBoxWrapper}
          sx={{ minHeight: isGeneralSettingsActive ? '0px' : '126px' }}
        >
          {control._formValues.wifi_settings.filter(
            (element: any) =>
              element.settingType !== SettingTypeEnum.DELETE &&
              ((isGeneralSettingsActive && !element.isS2Series) ||
                (!isGeneralSettingsActive && element.isS2Series)),
          ).length === 0 ? (
            // No wifi setting
            <Box className={`${classes.noWifiBox} ${'no-wifi-box'}`}>
              {/* middle add wifi button */}
              <Typography
                sx={{
                  fontWeight: 'medium',
                  lineHeight: '24px',
                  fontSize: '16px',
                  color: '#313133',
                  marginBottom: '16px',
                  textAlign: 'center',
                }}
              >
                {t('remoteDevice.label.noWifi')}
              </Typography>
              {EditAccessForWifi && (
                <Button
                  aria-label="Update"
                  onClick={() => {
                    setAddButtonDisable(true);
                    handleAppend({
                      ssid: '',
                      password: '',
                      securityType: isGeneralSettingsActive ? 0 : 3,
                      proxyType: 0,
                      proxyHostName: '',
                      proxyPort: '',
                      bypassProxy: '',
                      pacUrl: '',
                      wifiIPSettingType: 0,
                      ipAddress: '',
                      gateway: '',
                      networkPrefixLength: '',
                      dns1: '',
                      dns2: '',
                      advanceOpt: false,
                      isS2Series: !isGeneralSettingsActive,
                      settingType: SettingTypeEnum.ADD,
                      eapMethod: {
                        eapType: 0,
                        peap: null,
                        tls: null,
                      },
                      caDomainName: '',
                      caUserPassword: '',
                      identity: '',
                      anonymousIdentity: '',
                      deleteFlag: 0,
                      groupInfoId: +groupId!,
                      id: 0,
                    });
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'add_wifi');
                  }}
                  sx={{
                    p: '9px 16px',
                    height: '38px',
                    background: '#009DE1',
                    borderRadius: '6px',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#007EB4',
                    },
                    '&.Mui-disabled': {
                      color: 'white',
                      background: '#009DE1',
                      opacity: 0.5,
                    },
                  }}
                  disableRipple
                  disabled={!EditAccessForWifi}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#FFFFFF',
                    }}
                  >
                    {t('remoteDevice.button.addWifi')}
                  </Typography>
                </Button>
              )}
            </Box>
          ) : (
            accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI?.includes(
              FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.VIEWDETAILS,
            ) && (
              <div style={{ margin: '0 0 20px' }}>
                {/* Wifi Setting Array Start */}
                {control._formValues.wifi_settings.map(
                  (field: any, index: number) => {
                    if (
                      control._formValues.wifi_settings[index].settingType !==
                      SettingTypeEnum.DELETE
                    ) {
                      if (isGeneralSettingsActive && !field.isS2Series) {
                        return (
                          <WifiSettingsCard
                            id={field.id}
                            field={field}
                            register={register}
                            index={index}
                            errors={errors}
                            control={control}
                            deleteHandler={deleteHandler}
                            setValue={setValue}
                            checkSaveButtonDisable={checkSaveButtonDisable}
                            formField={formField}
                            setError={setError}
                            isGeneralSettingsActive={isGeneralSettingsActive}
                            validateInput={validateInput}
                            setIsMenuOpen={setIsMenuOpen}
                          />
                        );
                      } else if (!isGeneralSettingsActive && field.isS2Series) {
                        return (
                          <WifiSettingsCard
                            id={field.id}
                            field={field}
                            register={register}
                            index={index}
                            errors={errors}
                            control={control}
                            deleteHandler={deleteHandler}
                            setValue={setValue}
                            checkSaveButtonDisable={checkSaveButtonDisable}
                            formField={formField}
                            setError={setError}
                            isGeneralSettingsActive={isGeneralSettingsActive}
                            validateInput={validateInput}
                            setIsMenuOpen={setIsMenuOpen}
                          />
                        );
                      }
                    } else {
                      return null;
                    }
                  },
                )}
                <DeleteDialog
                  open={deleteDialogOpen}
                  onClose={() => {
                    setDeleteDialogOpen(false);
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'cancel');
                  }}
                  onSubmit={() => {
                    deleteHandleMOdal(deleteIndex);
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'delete');
                    sendGAEvent('press_btn', 'txt_name', groupId?.toString());
                    sendGAEvent(
                      'press_btn',
                      'txt_name',
                      deleteIndex.toString(),
                    );
                    checkSaveButtonDisable();
                  }}
                  icon={'helllos'}
                  title={t('remoteDevice.message.deleteConfirmation')}
                  subTitle={t('groupDetail.label.deleteCaution')}
                  btnBgColor="#E1341E"
                  btnBgColorHover="#CC240F"
                  btnTextNegative={t('groupDetail.label.cancelBtn')}
                  btnTextPositive={t('groupDetail.label.deleteBtn')}
                />
              </div>
            )
          )}
        </Box>
        {/* submit btn */}
      </form>
    </div>
  );
});

export default NewWifi;
