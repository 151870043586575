/* eslint-disable @typescript-eslint/no-unsafe-return */

const customStylesMd = (extraProps?: {
  height?: number;
  borderRadius?: number;
}) => ({
  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    background: '#fff',
    minHeight: extraProps && extraProps.height ? extraProps.height : '38px',
    height: extraProps && extraProps.height ? extraProps.height : '38px',
    borderRadius:
      extraProps && extraProps.borderRadius ? extraProps.borderRadius : '6px',
    boxShadow: state.isFocused ? '0px 0px 1px #939598' : null,
    border: state.isFocused ? '1px solid #939598' : '1px solid #d9d9d9',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#939598',
    },
    '&:focus': {
      borderColor: '#939598',
    },
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    height: extraProps && extraProps.height ? extraProps.height : '38px',
    padding: '0 8px',
    marginTop: '-1px',
  }),

  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: extraProps && extraProps.height ? extraProps.height : '38px',
    marginTop: '-1px',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius:
      extraProps && extraProps.borderRadius ? extraProps.borderRadius : '6px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    height: extraProps && extraProps.height ? extraProps.height - 1 : '37px',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
    color: '#313133',
    ':active': {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
    },
    ':first-child': {
      borderTopRightRadius:
        extraProps && extraProps.borderRadius ? extraProps.borderRadius : '6px',
      borderTopLeftRadius:
        extraProps && extraProps.borderRadius ? extraProps.borderRadius : '6px',
    },
    ':last-child': {
      borderTopRightRadius:
        extraProps && extraProps.borderRadius ? extraProps.borderRadius : '6px',
      borderTopLeftRadius:
        extraProps && extraProps.borderRadius ? extraProps.borderRadius : '6px',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#313133',
  }),
});

export default customStylesMd;
