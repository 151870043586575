/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, {
  components,
  DropdownIndicatorProps,
  NoticeProps,
  GroupBase,
  OptionProps,
  // OptionsOrGroups,
} from 'react-select';
// import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Box,
  Button,
  TextField,
  Typography,
  Tooltip,
  Input,
  InputAdornment,
  IconButton,
  // ClickAwayListener,
  Skeleton,
} from '@mui/material';
import RegistrationInformationCard from '../Modecules/RegistrationInformationCard';
import PersonalConsoleSettingCard from '../Modecules/PersonalConsoleSettingCard';
import usePersonalSettings from '../../hooks/usePersonalSettings';
import PersonalSettingsTemplate from '../Templates/PersonalSettingsTemplate';
import type { UserProfileInput } from '../../types/inputs/userProfileInput';
import { ReactComponent as RightWhite } from '../../images/icon/Right_white.svg';
import { ReactComponent as UploadIcon } from '../../images/icon/uploadIcon.svg';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as Password } from '../../images/icon/Password.svg';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';
import { useStyles } from '../Templates/CommonStyle';
import customStylesMd from '../../utils/customizeReactSelect/customStylesMd';
import useSnackbar from '../../hooks/useSnackbar';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import {
  FEATURE_PERMISSION,
  PAGE_PATH_NAME,
  USER_PASSWORD_PATTERN,
} from '../../constants/constants';
import Loader from '../Atoms/Loader';
import useAccountInfo from '../../hooks/useAccountInfo';
import { sendGAEvent } from '../../hooks/useGAEvent';
import DisabledInputField from '../Atoms/DisabledInputField';
import type { ModifiedLanguageInfo } from '../../types/apis/languageListApi';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';

dayjs.extend(utc);

const PersonalSettings = (): JSX.Element => {
  const classes = useStyles();
  const { localeLanguage } = useLocale();
  const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();
  // const matches = useMediaQuery('(max-width: 1199.98px)');

  const {
    setSelectedFile,
    selectedFile,
    isChangeUserName,
    isChangeLanguage,
    isCallingUpdateApi,
    handleOnChangeUserName,
    handleOnChangeLanguage,
    onClickSaveBtn,
    personalSettingsData,
    isCallingApi,
    groupNameList,
    updateSuccess,
    setApiImageRemoved,
    localeList,
    isCallingLocaleListApi,
  } = usePersonalSettings();

  const { accountInfo } = useAccountInfo();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // File select
  const [isImageHoverEffect, setIsImageHoverEffect] = useState(true);
  const [isImageHover, setIsImageHover] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    // Check if the file is an image
    const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (file && !imageTypes.includes(file.type)) {
      if (file.type.startsWith('image/')) {
        displaySnackbar({
          message: t('personalSetting.message.imgType'),
          type: 'error',
        });
      } else {
        displaySnackbar({
          message: t('personalSetting.message.imgFile'),
          type: 'error',
        });
      }

      return;
    }

    if (file && file.size <= 2097152) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      setSelectedFile(file || null);
    } else {
      displaySnackbar({
        message: t('personalSetting.message.fileSize'),
        type: 'error',
      });
    }
  };

  const handleImageClick = (
    clickEvent:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    setIsImageHover(false);
    setIsImageHoverEffect(false);
    if (previewImage) {
      if (
        clickEvent.type === 'click' &&
        clickEvent.target instanceof HTMLImageElement
      ) {
        // img area Click
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event) =>
          handleFileSelect(
            event as unknown as React.ChangeEvent<HTMLInputElement>,
          );
        input.click();
      } else {
        // remove text Click
        setPreviewImage(null);
        setSelectedFile(null);
        if (previewImage) {
          setApiImageRemoved(true);
        }
      }
    } else {
      // Is browse area is empty
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (event) =>
        handleFileSelect(
          event as unknown as React.ChangeEvent<HTMLInputElement>,
        );
      input.click();
    }

    // GA tags
    sendGAEvent('press_btn', 'btn_name', 'upload_icon');
  };
  // End File select

  // Tooltip
  const [tooltipEnabledUserData, setTooltipEnabledUserData] = useState(false);
  const handleShowTooltipUserData = (event: React.MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;

    const getMaxWidth = element.style.maxWidth;

    // Set the element's overflow to visible
    element.style.overflow = 'visible';
    element.style.maxWidth = 'fit-content';

    // Get the scroll width
    const scrollWidth = element.getBoundingClientRect().width;

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';
    element.style.maxWidth = getMaxWidth;

    if (scrollWidth > clientWidth) {
      setTooltipEnabledUserData(true);
    } else {
      setTooltipEnabledUserData(false);
    }
  };

  // start form
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    trigger,
    clearErrors,
  } = useForm<UserProfileInput>({
    criteriaMode: 'all',
    shouldFocusError: false,
    mode: 'onChange' || 'onSubmit',
  });
  const newUserName = watch('userName');

  const validateUserName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('common.validationError.name.maxLength');
    }

    return true;
  };
  // remove error on Click Away
  // const handleClickAwayNameField = () => {
  //   clearErrors('userName');
  // };
  // start name Field close Icon
  const [activateNameCloseIcon, setActivateNameCloseIcon] = useState(false);
  const [isFocusedName, setIsFocusedName] = useState(false);

  const handlActivateNameCloseIcon = () => {
    if (newUserName?.length !== 0) {
      setActivateNameCloseIcon(true);
    }
  };

  const handlDeactivateNameCloseIcon = () => {
    if (!isFocusedName) {
      setActivateNameCloseIcon(false);
    }
  };
  const handleFocus = () => {
    setIsFocusedName(true);
  };

  const handleBlur = () => {
    setIsFocusedName(false);
    setActivateNameCloseIcon(false);
  };
  // end name Field close Icon

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  // end password card

  const passwordFormValue = watch('newPassword')?.toString();

  const oldPasswordFormValue = watch('oldPassword');
  const imageLogic =
    (personalSettingsData?.imageUrl && previewImage === null) || selectedFile;
  // enable save btn
  const [saveBtnEnabled, setSaveBtnEnabled] = useState<boolean>(false);
  useEffect(() => {
    if (
      (isChangeUserName ||
        isChangeLanguage ||
        imageLogic ||
        (passwordFormValue && oldPasswordFormValue)) &&
      !errors.userName &&
      !errors.newPassword &&
      !errors.oldPassword
    ) {
      setSaveBtnEnabled(true);
    } else {
      setSaveBtnEnabled(false);
    }
  }, [
    isChangeUserName,
    isChangeLanguage,
    passwordFormValue,
    oldPasswordFormValue,
    imageLogic,
    errors.userName,
    errors.newPassword,
    errors.oldPassword,
  ]);

  // const handleClickAwayOldPasswordField = () => {
  //   clearErrors('oldPassword');
  // };

  // const handleClickAwayNewPasswordField = () => {
  //   clearErrors('newPassword');
  // };

  useEffect(() => {
    // if (personalSettingsData.imageUrl) {
    //   const imageString = `data:${personalSettingsData.extension};base64,${personalSettingsData.imageUrl}`;
    //   setPreviewImage(imageString);
    //   setSelectedFile(null);
    // }
    setPreviewImage(personalSettingsData.imageUrl);
    setValue('userName', personalSettingsData.userName);
    setValue('localeCode', personalSettingsData.localeCode);
  }, [personalSettingsData, setValue]);

  useEffect(() => {
    if (updateSuccess === true) {
      setValue('oldPassword', '');
      setValue('newPassword', '');
    }
    // eslint-disable-next-line
  }, [updateSuccess]);

  useEffect(() => {
    if (!oldPasswordFormValue) {
      clearErrors('newPassword');
    }
    // eslint-disable-next-line
  }, [oldPasswordFormValue]);
  useEffect(() => {
    if (!passwordFormValue) {
      clearErrors('oldPassword');
    }
    // eslint-disable-next-line
  }, [passwordFormValue]);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      !accountInfo.featurePermissions?.PROFILEMANAGEMENT?.USERPROFILE?.includes(
        FEATURE_PERMISSION.PROFILEMANAGEMENT.USERPROFILE.VIEWDETAILS,
      )
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [pathname]);
  const editName =
    accountInfo.featurePermissions?.PROFILEMANAGEMENT?.USERNAME?.includes(
      FEATURE_PERMISSION.PROFILEMANAGEMENT.USERNAME.EDIT,
    );

  // customize react-select
  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.DropdownIndicator {...props}>
      <ArrowDown className={classes.selectArrow} />
    </components.DropdownIndicator>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const Option = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.Option {...props}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.isSelected && <CheckIcon />}
    </components.Option>
  );
  // custom message when there are no options
  // eslint-disable-next-line react/no-unstable-nested-components
  const NoOptionsMessage = (
    props4: NoticeProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.NoOptionsMessage {...props4}>
      {t('common.label.noOptions')}
    </components.NoOptionsMessage>
  );

  const onChangePassword = () => {
    fetch(
      `https://${
        process.env.REACT_APP_AUTH0_DOMAIN ?? '#'
      }/dbconnections/change_password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email: accountInfo.email,
          connection: 'Username-Password-Authentication',
        }),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.text();
        }
        throw new Error();
      })
      .then(() => {
        displaySnackbar({
          message: t('personalSetting.message.changePasswordSuccessMessage'),
          type: 'success',
          timeout: 4000,
        });
      })
      .catch((e: Error) => {
        // console.log(e.message);
        if (e.message === 'Failed to fetch') {
          return displaySnackbar({
            message: t('common.error.noInternet'),
            type: 'error',
            timeout: 3500,
          });
        }

        return displaySnackbar({
          message: 'An unknown error has occured',
          type: 'error',
          timeout: 3500,
        });
      });
    // GA tags
    sendGAEvent('press_btn', 'btn_name', 'change_passsword');
  };

  // End customize react-select

  return (
    <PersonalSettingsTemplate titleText={t('personalSetting.title')}>
      <Box
        sx={{ width: '100%' }}
        onMouseMove={() => {
          setIsImageHoverEffect(true);
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onClickSaveBtn)}>
          <Box className={classes.personalSettingBtnCard}>
            <Button
              id="userDetailSave"
              type="submit"
              variant="contained"
              className={classes.personalSettingBtn}
              disableRipple
              disabled={
                !saveBtnEnabled ||
                isCallingApi ||
                isCallingUpdateApi ||
                newUserName?.trim().length === 0
              }
            >
              {isCallingUpdateApi ? (
                <Loader colorCode="#fff" />
              ) : (
                <RightWhite />
              )}

              <Typography className={classes.personalSettingBtnText}>
                {t('personalSetting.button.save')}
              </Typography>
            </Button>
          </Box>
          <Box className={classes.personalSettingPageDivider}>
            <Box className="personal-setting-row">
              <Box className={classes.personalSettingPageDetailsCard}>
                <Box className={classes.personalSettingPageDetailsCardDivider}>
                  <Box className={classes.personalSettingPageDetailsCardHeader}>
                    {!isCallingApi && (
                      <Box>
                        <Input
                          type="file"
                          onChange={handleFileSelect}
                          style={{ display: 'none' }}
                        />
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={handleImageClick}
                          style={{
                            width: '120px',
                            height: '120px',
                            borderRadius: '50%',
                            cursor: 'pointer',
                          }}
                          className={classes.personalSettingImgCard}
                        >
                          {previewImage ? (
                            <Box
                              className={classes.personalSettingPageImgCard}
                              onMouseOver={() => {
                                setIsImageHover(true);
                              }}
                              onMouseOut={() => {
                                setIsImageHover(false);
                                setIsImageHoverEffect(true);
                              }}
                            >
                              <>
                                <img
                                  src={previewImage}
                                  alt="Preview"
                                  style={{
                                    width: '120px',
                                    height: '120px',
                                    background: '#F8F8F8',
                                    borderRadius: '50%',
                                    filter:
                                      isImageHoverEffect && isImageHover
                                        ? 'brightness(0.8)'
                                        : 'none',
                                  }}
                                />
                                <Typography
                                  className={
                                    classes.personalSettingPageImgRemoveText
                                  }
                                  sx={{
                                    display:
                                      isImageHoverEffect && isImageHover
                                        ? 'block'
                                        : 'none',
                                    right:
                                      // eslint-disable-next-line no-nested-ternary
                                      localeLanguage === LOCALE_CODE.JA
                                        ? '45px !important'
                                        : localeLanguage === LOCALE_CODE.FR
                                        ? '26px !important'
                                        : '35px',
                                  }}
                                >
                                  {t('personalSetting.button.remove')}
                                </Typography>
                              </>
                            </Box>
                          ) : (
                            <div
                              className={
                                classes.personalSettingPageImgUploadCard
                              }
                            >
                              {!isCallingApi && (
                                <>
                                  <UploadIcon />
                                  <Typography
                                    className={
                                      classes.personalSettingPageImgUploadText
                                    }
                                  >
                                    {t('personalSetting.button.upload')}
                                  </Typography>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </Box>
                    )}

                    {isCallingApi && (
                      <Skeleton
                        variant="circular"
                        animation="wave"
                        sx={{
                          p: '0px',
                          width: '120px',
                          height: '120px',
                          // borderRadius: '50%',
                        }}
                      />
                    )}

                    {isCallingApi && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            p: '0px',
                            width: '170px',
                            height: '36px',
                            marginBottom: '4px',
                          }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            p: '0px',
                            width: '90px',
                            height: '20px',
                            marginBottom: '4px',
                          }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ p: '0px', width: '170px', height: '20px' }}
                        />
                      </Box>
                    )}

                    {!isCallingApi && (
                      <Box>
                        <Tooltip
                          placement="top"
                          title={
                            personalSettingsData.userName &&
                            personalSettingsData.userName
                          }
                          onMouseEnter={handleShowTooltipUserData}
                          disableHoverListener={!tooltipEnabledUserData}
                          arrow
                          sx={{ marginTop: 0 }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [-7, -7],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.tooltipStyle,
                            },
                          }}
                        >
                          <Typography
                            className={classes.personalSettingPageUserName}
                          >
                            {personalSettingsData.userName}
                          </Typography>
                        </Tooltip>
                        <Typography
                          className={classes.personalSettingPageUserRole}
                          sx={{
                            fontSize: '15px',
                            lineHeight: '24px',
                            marginBottom: '4px',
                            color: '#939598',
                          }}
                        >
                          {personalSettingsData.roleName}
                        </Typography>
                        <Tooltip
                          placement="top"
                          title={personalSettingsData.mailAddress}
                          onMouseEnter={handleShowTooltipUserData}
                          disableHoverListener={!tooltipEnabledUserData}
                          arrow
                          sx={{ marginTop: 0 }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [-7, -7],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.tooltipStyle,
                            },
                          }}
                        >
                          <Typography
                            className={classes.personalSettingPageUserEmail}
                          >
                            {personalSettingsData.mailAddress}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}
                  >
                    {t('personalSetting.label.name')}
                  </Typography>
                  {editName && (
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  )}
                </Box>

                {isCallingApi && (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '38px',
                      marginTop: '6px',
                      marginBottom: '8px',
                    }}
                  />
                )}
                {!isCallingApi && (
                  // <ClickAwayListener onClickAway={handleClickAwayNameField}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}
                  >
                    {editName ? (
                      <Controller
                        control={control}
                        name="userName"
                        rules={{
                          required: t('common.validationError.required'),
                          validate: validateUserName,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            value={field.value || ''}
                            fullWidth
                            margin="normal"
                            placeholder={t('personalSetting.placeholder.name')}
                            autoComplete="off"
                            error={Boolean(errors.userName)}
                            // autoFocus
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onMouseOver={handlActivateNameCloseIcon}
                            onMouseLeave={handlDeactivateNameCloseIcon}
                            inputProps={{
                              maxLength: 257,
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginLeft: '0px', width: '20px' }}
                                >
                                  <IconButton
                                    aria-label="close"
                                    onClick={() => {
                                      setActivateNameCloseIcon(false);
                                      setValue('userName', '');
                                      void trigger('userName');

                                      // GA tags
                                      sendGAEvent(
                                        'press_btn',
                                        'btn_name',
                                        'delete_input_user_name',
                                      );
                                    }}
                                    onMouseDown={handleMouseDown}
                                    edge="end"
                                    sx={{
                                      p: '8px',
                                      // marginLeft: '8px',
                                      // marginRight: '-8px',
                                      '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                      },
                                      display: activateNameCloseIcon
                                        ? ''
                                        : 'none',
                                    }}
                                    disableRipple
                                  >
                                    <CloseIcon
                                      style={{
                                        width: '9.01px',
                                        height: '9.01px',
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: errors.userName
                                  ? '#E1341E !important'
                                  : '#939598 !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '6px',
                                '&.Mui-focused fieldset': {
                                  border: errors.userName
                                    ? '1px solid #E1341E !important'
                                    : '1px solid #939598 !important',
                                },
                              },
                            }}
                            className={classes.loginFormInput}
                            onChange={(event) => {
                              handleOnChangeUserName(event.target.value);
                              field.onChange(event);

                              if (event.target.value.length !== 0) {
                                setActivateNameCloseIcon(true);
                              } else {
                                setActivateNameCloseIcon(false);
                              }
                            }}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        name="userName"
                        disabled
                        value={personalSettingsData.userName}
                        fullWidth
                        margin="normal"
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#939598',
                            backgroundColor: '#F8F8F8',
                            borderRadius: '6px',
                          },

                          '.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D9D9D9 !important',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                          },
                        }}
                        className={classes.loginFormInput}
                      />
                    )}

                    {errors.userName && (
                      <Box className={classes.userNameError}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {errors.userName && errors.userName.message}
                        </p>
                      </Box>
                    )}
                  </Box>
                  // </ClickAwayListener>
                )}
                <Typography className={classes.personalSettingPageEmailTitle}>
                  {t('personalSetting.label.email')}
                </Typography>

                {isCallingApi && (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '38px',
                      marginTop: '6px',
                      marginBottom: '8px',
                    }}
                  />
                )}
                {!isCallingApi && (
                  <DisabledInputField text={personalSettingsData.mailAddress} />
                )}
                <Box sx={{ marginTop: '-7px', marginBottom: '8px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      marginBottom: '6px',
                      height: '20px',
                    }}
                  >
                    <Typography className={classes.loginCardLevel}>
                      {t('personalSetting.label.language')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  {isCallingApi && (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        p: '0px',
                        height: '38px',
                        marginTop: '6px',
                        marginBottom: '8px',
                      }}
                    />
                  )}
                  {!isCallingApi && (
                    <Controller
                      name="localeCode"
                      render={({ field: { onChange, value, name } }) => (
                        <Select
                          placeholder={
                            isCallingLocaleListApi ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{
                                  p: '0px',
                                  width: '64px',
                                  height: '20px',
                                }}
                              />
                            ) : (
                              <Typography
                                sx={{ fontSize: '14px', color: '#939598' }}
                              >
                                {t('personalSetting.label.language')}
                              </Typography>
                            )
                          }
                          value={localeList.find((c) => c.value === value)}
                          name={name}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore:next-line
                          onChange={(selectedOption: ModifiedLanguageInfo) => {
                            onChange(selectedOption.value);
                            handleOnChangeLanguage(selectedOption.value);
                            // GA tag
                            sendGAEvent(
                              'select_list',
                              'txt_name',
                              'select_language_list',
                            );
                            sendGAEvent(
                              'select_list',
                              'select_language_list_value',
                              selectedOption.value,
                            );
                          }}
                          options={localeList}
                          isSearchable={false}
                          components={{
                            DropdownIndicator,
                            Option,
                            NoOptionsMessage,
                          }}
                          className={classes.reactSelect}
                          classNamePrefix="react-select"
                          styles={customStylesMd()}
                          // isDisabled
                        />
                      )}
                      control={control}
                    />
                  )}
                </Box>
              </Box>
              <Box className={classes.personalSettingPagePasswordCard}>
                <Box className={classes.personalSettingPagePasswordCardHeader}>
                  <Password />
                  <Typography
                    className={
                      classes.personalSettingPagePasswordCardHeaderText
                    }
                  >
                    {t('personalSetting.label.passSettings')}
                  </Typography>
                </Box>
                <Button
                  className={classes.personalSettingPagePasswordCardButton}
                  onClick={onChangePassword}
                >
                  {t('personalSetting.label.changePassword')}
                </Button>
              </Box>
            </Box>
            <Box className="personal-setting-row">
              <RegistrationInformationCard
                regDate={
                  personalSettingsData?.registeredDt
                    ? dayjs(
                        personalSettingsData.registeredDt.toString(),
                      ).format('YYYY/MM/DD')
                    : ''
                }
                invDate={
                  personalSettingsData?.invitedDt
                    ? dayjs(personalSettingsData.invitedDt.toString()).format(
                        'YYYY/MM/DD',
                      )
                    : ''
                }
                isCallingApi={isCallingApi}
              />
              <PersonalConsoleSettingCard
                role={personalSettingsData.roleName}
                groupNameList={groupNameList}
                isCallingApi={isCallingApi}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </PersonalSettingsTemplate>
  );
};

export default PersonalSettings;
