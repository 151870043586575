import { useEffect, useState, SetStateAction, Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import { CORPORATE_DETAILS_API_RESULT_CODE } from '../apis/callGetCorporateDetailsApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import {
  CorporateProfileReturnType,
  getCorporateProfile,
  updateCorporateProfile,
} from '../utils/corporateProfile/corporateProfile';
import {
  CorporateContact,
  SubscriptionInfoType,
  UpdateCorporateDetailsApiRequest,
  UpdateCorporateDetailsApiResponse,
} from '../types/apis/corporateDetailsApi.d';
import { UPDATE_CORPORATE_DETAILS_API_RESULT_CODE } from '../apis/callUpdateCorporateDetailsApi';
import useSnackbar from './useSnackbar';
import useAccountInfo from './useAccountInfo';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import { sendGAEvent } from './useGAEvent';

export type countryInfo = {
  dialCode: string;
  countryFlagCode: string;
};
export type UseCorporateProfile = {
  isSaveBtnEnabled: boolean;
  setIsSaveBtnEnabled: Dispatch<SetStateAction<boolean>>;
  onClickSaveBtn: (inputData: any) => void;
  onClickSubscriptionBtn: () => void;
  onClickOrderHistoryBtn: () => void;
  onClickPaymentInfoBtn: () => void;
  // contactInfo
  contactInfo: CorporateContact;
  isCallingApi: boolean;
  isCallingUpdateApi: boolean;
  t: TFunction;
  subscriptionInfo: SubscriptionInfoType;
};

const useCorporateProfile = (): UseCorporateProfile => {
  const [isSaveBtnEnabled, setIsSaveBtnEnabled] = useState<boolean>(false);
  const { displaySnackbar } = useSnackbar();
  const { accountInfo, saveCompany } = useAccountInfo();
  const { t } = useSwitchLocaleLanguage();

  // subscription info
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<SubscriptionInfoType>({
      subscriptionLink: '',
      currentPlan: '',
      expiredDate: '',
      nextBillingDate: '',
      trialFlag: false,
    });

  // urls object
  const [urlList, setUrlList] = useState<{
    subscriptionUrl: string;
    orderHistoryUrl: string;
    paymentInfoUrl: string;
  }>({
    subscriptionUrl: '',
    orderHistoryUrl: '',
    paymentInfoUrl: '',
  });
  const onClickSubscriptionBtn = () => {
    window.open(urlList.subscriptionUrl, '_blank', 'noreferrer');
  };
  const onClickOrderHistoryBtn = () => {
    window.open(urlList.orderHistoryUrl, '_blank', 'noreferrer');

    // GA tags
    sendGAEvent('press_btn', 'link_name', 'order_history_link');
    sendGAEvent('press_btn', 'order_history_link', accountInfo.corpId);
  };
  const onClickPaymentInfoBtn = () => {
    window.open(urlList.paymentInfoUrl, '_blank', 'noreferrer');

    // GA tags
    sendGAEvent('press_btn', 'link_name', 'payment_info_link');
    sendGAEvent('press_btn', 'payment_info_link', accountInfo.corpId);
  };

  const [contactInfo, setContactInfo] = useState<CorporateContact>({
    corpId: '',
    email: '',
    corporateName: '',
  });

  // start
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [isCallingUpdateApi, setIsCallingUpdateApi] = useState<boolean>(false);

  const getCorporateDetails = () => {
    setIsCallingApi(true);
    /*eslint-disable*/
    getCorporateProfile({ corpId: accountInfo.corpId })
      .then((result: CorporateProfileReturnType) => {
        switch (result.message) {
          case CORPORATE_DETAILS_API_RESULT_CODE.CORP_DETAIL_NOT_FOUND: {
            displaySnackbar({
              message: t('corporateProfile.message.noCorporate'),
              type: 'error',
            });
            break;
          }
          case CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
            {
              saveCompany(result?.details?.corporateInfoResponse.corporateName);
              setContactInfo({
                // ...result.details.corporateContactResponse,
                corpId: result.details.corporateContactResponse.corpId
                  ? result.details.corporateContactResponse.corpId
                  : '',

                email: result.details.corporateContactResponse.email
                  ? result.details.corporateContactResponse.email
                  : '',
                corporateName:
                  result.details.corporateInfoResponse.corporateName,
              });
            }

            // setting subscription info
            setSubscriptionInfo(result.details?.subscriptionInfoResponse);

            // setting URLs
            const tempUrl = {
              subscriptionUrl: result.details.subscriptionInfoResponse
                .subscriptionLink
                ? result.details.subscriptionInfoResponse.subscriptionLink
                : '',
              orderHistoryUrl: result.details.orderInfoResponse.orderHistoryLink
                ? result.details.orderInfoResponse.orderHistoryLink
                : '',
              paymentInfoUrl: result.details.paymentInfoResponse.paymentInfoLink
                ? result.details.paymentInfoResponse.paymentInfoLink
                : '',
            };
            setUrlList(tempUrl);
            break;

          case SERVER_MESSAGE.USER_CONSENT_REQUIRED: {
            break;
          }
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.USER_ROLE_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          default:
            throw result.message;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'Error details');
        displaySnackbar({
          message: t('common.error.serverErr'),
          type: 'error',
        });
        setIsCallingApi(false);
      })
      .finally(() => {
        setIsCallingApi(false);
      });
  };

  useEffect(() => {
    getCorporateDetails();
  }, []);

  const updateCorporateDetails = (
    requestPayload: UpdateCorporateDetailsApiRequest,
  ) => {
    setIsCallingUpdateApi(true);
    setIsCallingApi(true);

    /*eslint-disable*/
    updateCorporateProfile(requestPayload)
      .then((result: UpdateCorporateDetailsApiResponse) => {
        switch (result.message) {
          case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_UPDATED: {
            setIsSaveBtnEnabled(false);
            getCorporateDetails();
            // success message
            displaySnackbar({
              message: t('corporateProfile.message.updateSuccess'),
            });

            break;
          }
          case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED: {
            displaySnackbar({
              message: t('corporateProfile.message.changeNotAllowed'),
              type: 'error',
            });
            break;
          }
          case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.USER_IS_NOT_ADMIN: {
            displaySnackbar({
              message: t('corporateProfile.message.adminEmail'),
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.USER_ROLE_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          default:
            throw result.message;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        displaySnackbar({
          message: t('corporateProfile.message.updateFailed'),
          type: 'error',
        });
        setIsCallingApi(false);
      })
      .finally(() => {
        setIsCallingUpdateApi(false);
        setIsCallingApi(false);
      });
  };

  // handle save btn
  const onClickSaveBtn = (inputData: {
    address: string;
    corporateName: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    zipCode: string;
  }) => {
    const temp = {
      corpId: accountInfo.corpId,
      corporateName: inputData.corporateName
        ? inputData.corporateName.trim()
        : '',
      email: inputData.email ? inputData.email.trim() : '',
    };

    updateCorporateDetails(temp);

    // GA tags
    sendGAEvent('press_btn', 'btn_name', 'save');
    sendGAEvent('press_btn', 'save_value', accountInfo.corpId);
    sendGAEvent('press_btn', 'input_corporate_name_value', temp.corporateName);
    sendGAEvent('press_btn', 'input_contract_email_value', temp.email);
  };

  return {
    isSaveBtnEnabled,
    setIsSaveBtnEnabled,
    onClickSaveBtn,
    onClickSubscriptionBtn,
    onClickOrderHistoryBtn,
    onClickPaymentInfoBtn,
    // contactInfo
    contactInfo,
    isCallingApi,
    isCallingUpdateApi,
    t,
    subscriptionInfo,
  };
};

export default useCorporateProfile;
