import React from 'react';
import './ptloaderstyles.scss';

const PTloader = () => (
  <div className="loaderParentContainer">
    <div className="loaderContainerCss">
      <div className="pt-loader-svg">
        <svg
          width="300"
          height="176"
          viewBox="0 0 800 176"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="anim-path  path1 "
            d="M775.8 158.1C778 157.2 778.8 158.1 779.4 159.9C780 161.7 781.6 165.9 783 169.7C784.4 165.9 785.6 162.9 786.5 159.8C787.4 156.7 788 157.3 790.3 158.1C790.3 162.2 790.3 166.6 790.2 170.9C790.2 171.4 789.6 171.8 789.3 172.3C789 172.8 788.4 171.3 788.4 170.8C788.411 167.952 788.177 165.108 787.7 162.3C786.8 164.6 786.1 166.9 785.1 169.2C784.471 170.311 783.77 171.38 783 172.4C782.242 171.429 781.572 170.391 781 169.3C780.033 166.793 778.897 164.354 777.6 162C777.6 164.8 777.7 167.7 777.6 170.5C777.461 171.149 777.189 171.762 776.8 172.3C776.4 171.8 775.8 171.2 775.8 170.7C775.7 166.5 775.8 162.3 775.8 158.1Z"
            fill="black"
          />
          <path
            className="anim-path  path2"
            d="M774.1 159.3L768.9 159.6V170.2C768.722 170.93 768.453 171.636 768.1 172.3C767.604 171.651 767.262 170.899 767.1 170.1C767 166.6 767 163.2 767 159.6L762.1 159.3C763.2 157.3 772 157.1 774.1 159.3Z"
            fill="black"
          />
          <path
            className="anim-path  path3"
            d="M752.899 172.7H723.199C722.399 172.7 721.099 171.5 720.699 170.6C715.999 159.9 711.399 149.2 706.799 138.4C706.536 137.612 706.049 136.918 705.399 136.4C705.399 137.3 705.299 138.3 705.299 139.3C705.299 149.5 705.199 159.7 705.299 169.8C705.299 172.1 704.799 172.9 702.399 172.8H677.199V64.8002H705.199V97.9002L705.899 98.1002C710.799 86.9002 715.799 75.8002 720.699 64.7002H750.599C749.899 65.9002 749.499 66.9002 748.899 67.9002C740.699 82.2002 732.499 96.6002 724.199 110.9C723.662 111.615 723.355 112.476 723.319 113.369C723.283 114.262 723.521 115.145 723.999 115.9C733.399 134 742.599 152.1 751.899 170.1C752.199 170.8 752.399 171.5 752.899 172.7Z"
            fill="black"
          />
          <path
            className="anim-path  path4"
            d="M639.5 148.6H655.1V172.5H611.4V64.7998H639.5V148.6Z"
            fill="black"
          />
          <path
            className="anim-path  path5"
            d="M592.9 172.5L589.6 172.7H565.8C563.8 172.8 563.1 172.1 562.8 170.2C562.3 166.1 561.6 162 560.9 157.6C555.2 157.6 549.6 157.5 544 157.7C543.4 157.7 542.6 158.8 542.4 159.5C541.7 163.2 541.3 167 540.5 170.7C540.4 171.4 539.3 172.6 538.7 172.6H511.6L510.9 172.2C511.5 169.8 512.1 167.3 512.8 164.8C521.9 132.2 531.1 99.6 540.1 67C540.7 65.1 541.4 64.5 543.5 64.5C549.8 64.7 556.1 64.6 562.7 64.6C572.7 100.5 582.8 136.3 592.9 172.5ZM552.1 103.6H551.4L546.3 135.6H557.3C555.6 124.8 553.9 114.2 552.1 103.6Z"
            fill="black"
          />
          <path
            className="anim-path  path6"
            d="M490.099 172.7H463.499C462.899 172.7 462.099 171.6 461.699 170.8C461.599 170.036 461.599 169.263 461.699 168.5V88.4998H449.699V64.7998H502.199V88.2998H490.099V172.7Z"
            fill="black"
          />
          <path
            className="anim-path path7"
            d="M443.901 4.90039C454.801 22.4004 465.401 39.6004 476.001 56.8004L475.601 57.2004L422.301 28.2004L443.901 4.90039Z"
            fill="black"
          />
          <path
            className="anim-path path8"
            d="M504.901 0L527.401 20.2L485.701 47.6L485.201 47.3L504.901 0Z"
            fill="black"
          />
          <path
            className="anim-path path9"
            d="M381.9 64.7002H428.7V88.3002H410.3V106.2H426.9V130.2H410.3V148.5H428.7V172.4H381.9V64.7002Z"
            fill="black"
          />
          <path
            className="anim-path path10"
            d="M369.5 172.7H339.7C338.8 172.7 337.5 171.3 337.1 170.3C332.1 158.9 327.2 147.4 321.9 135.9V172.8H298.3C292.7 172.7 293.4 173.5 293.4 168.1V64.7996H321.7V98.2996H322.3C325.2 91.6996 328.1 85.0996 331.1 78.5996C332.8 74.4996 334.6 70.3996 336.4 66.2996C336.8 65.5996 337.8 64.5996 338.5 64.5996C347.9 64.3996 357.3 64.4996 367.2 64.4996C366.5 65.6996 366.1 66.5996 365.6 67.4996C357.3 81.7996 349.2 96.1996 340.8 110.5C340.236 111.362 339.936 112.37 339.936 113.4C339.936 114.43 340.236 115.437 340.8 116.3C350.2 134.6 359.6 152.8 368.9 171.1C369.151 171.612 369.352 172.148 369.5 172.7Z"
            fill="black"
          />
          <path
            className="anim-path path11"
            d="M269.2 61.8998V83.2998C269.2 85.6998 269.9 88.6998 268.8 90.3998C267.7 92.0998 264.6 91.3998 262.4 91.8998C250 94.7998 241.2 106.4 241.4 119.6C241.6 132.8 251.1 143.2 263.3 145.6L269 146.4V175.5C241.2 176.1 211.9 153.7 212 118.6C212.1 83.4998 241.8 61.1998 269.2 61.8998Z"
            fill="black"
          />
          <path
            className="anim-path path12"
            d="M199.4 118.6C199.413 126.086 197.951 133.5 195.099 140.421C192.247 147.341 188.059 153.632 182.775 158.935C177.492 164.237 171.216 168.446 164.305 171.323C157.394 174.2 149.985 175.687 142.5 175.7C110.9 175.7 85.5996 150.1 85.5996 118.1C85.5996 86.1002 111.3 61.7002 142.7 61.7002C157.756 61.7531 172.177 67.7713 182.805 78.4364C193.432 89.1015 199.4 103.544 199.4 118.6ZM142.7 91.2002C127.7 91.1002 115.1 103.3 115 118C114.9 132.7 126.9 146.1 141.9 146.1C156.9 146.1 169.9 134.3 170 118.9C170.026 115.289 169.341 111.708 167.983 108.361C166.625 105.015 164.622 101.968 162.087 99.396C159.552 96.8238 156.535 94.7761 153.208 93.3699C149.882 91.9636 146.311 91.2264 142.7 91.2002Z"
            fill="black"
          />
          <path
            className="anim-path path13"
            d="M28.2 140.4V167.6C28.1 173.5 29 172.6 23.1 172.7H0V64.7001H2.6C13.5 64.7001 24.5 64.5001 35.5 64.8001C40.628 64.862 45.7305 65.5334 50.7 66.8001C65.2 70.7001 74.3 82.3001 74.7 97.7001C75.046 103.84 74.5412 109.998 73.2 116C69.9 130 59.5 136.7 46.2 139.6C42.9535 140.073 39.6803 140.341 36.4 140.4H28.2ZM28.3 117.4C34.7 118.2 39.9 116.4 43.1 110.9C46.3 105.4 46.5 98.8001 42.7 93.0001C38.9 87.2001 34 87.3001 28.3 87.3001V117.4Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  </div>
);

export default PTloader;
