import * as React from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
// modal icons
import { ReactComponent as ModalWarningIcon } from '../../images/icon/warning.svg';
// import useSnackbar from '../../hooks/useSnackbar';
// mui custom style
import { useStyles } from '../Templates/CommonStyle';
import { RootState } from '../../state/store';
import useRedirectDialog from '../../hooks/useRedirectDialog';
// factory restore dialog
const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '460px',
    borderRadius: '12px',
    padding: '24px',
    alignSelf: 'flex-start',
    marginTop: '180px',
    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '18px',
      lineHeight: '26px',
      maxHeight: '53px',
      maxWidth: '285px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      marginLeft: 'auto',
    },
  },
}) as typeof Dialog;

type Props = {
  open?: boolean;
  //   onClickGroupList?: () => void;
  title?: string;
  message?: string;
  btnTitle?: string;
  routePath?: () => void;
};

const WarningDialog2 = React.memo<Props>((): JSX.Element => {
  const navigate = useNavigate();
  /*eslint-disable*/
  const redirectDialog = useSelector(
    (state: RootState) => state.redirectDialogSate,
  );
  const { closeRedirectDialog } = useRedirectDialog();
  // custom styles
  const classes = useStyles();

  // redirect to grouplist screen
  const handleDialog = () => {
    redirectDialog.routePath
      ? redirectDialog.routePath()
      : navigate(`/groups`, { replace: true });

    closeRedirectDialog();
  };

  return (
    <div>
      {/* warning dialog end */}
      <CustomDialog
        aria-labelledby="Warning Dialog"
        open={redirectDialog.open}
        onClose={handleDialog}
      >
        <Box className={classes.dialogBox}>
          <Box className={classes.dialogIcon}>
            <ModalWarningIcon />
          </Box>
          <Box sx={{ width: '100%' }}>
            <DialogTitle>{redirectDialog.title}</DialogTitle>
            <DialogContent className={classes.dialogContentCommon}>
              <Typography variant="body2" className={classes.dialogSubTitle}>
                {redirectDialog.message}
              </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActionsCommon}>
              <Button
                autoFocus
                className={`${classes.btnAction} ${classes.btnActionCommon}`}
                sx={{
                  backgroundColor: '#009DE1',
                  '&:hover': {
                    backgroundColor: '#007EB4 !important',
                  },
                }}
                onClick={handleDialog}
                disableRipple
              >
                {redirectDialog.btnTitle ? redirectDialog.btnTitle : 'Ok'}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </CustomDialog>
      {/* warning dialog end */}
    </div>
  );
});

export default WarningDialog2;
