import { useState } from 'react';
import {
  ALL_ERROR_TYPE,
  //   PAGE_PATH_NAME,
  SERVER_MESSAGE,
} from '../constants/constants';
import callReInviteApi from '../apis/callReInviteApi';
// import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

export type UseUserReinviteValue = {
  isCallingReInviteApi: boolean;
  onClickReInviteButton: (mailAddress: string) => void;
  invitationStatus: string;
};

const useUserReinvite = (): UseUserReinviteValue => {
  const [isCallingReInviteApi, setIsCallingReInviteApi] =
    useState<boolean>(false);
  const { displaySnackbar } = useSnackbar();
  const { t } = useSwitchLocaleLanguage();

  const [invitationStatus, setInvitationStatus] = useState<string>('');

  const onClickReInviteButton = (mailAddress: string) => {
    setIsCallingReInviteApi(true);
    void callReInviteApi(mailAddress)
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case SERVER_MESSAGE.UPDATE_OK: {
            setInvitationStatus(t('personalSetting.label.reInvited'));
            displaySnackbar({
              message: t('userInvite.message.reInviteMailSuccess'),
            });
            setIsCallingReInviteApi(false);

            break;
          }
          case SERVER_MESSAGE.USER_ALREADY_REGISTERED: {
            displaySnackbar({
              message: t('userInvite.message.alreadyRegistered'),
              type: 'error',
            });
            setInvitationStatus(t('personalSetting.label.registered'));

            break;
          }
          case SERVER_MESSAGE.NO_INTERNET: {
            displaySnackbar({
              message: t('common.error.noInternet'),
              type: 'error',
              timeout: 5000,
            });
            break;
          }
          case SERVER_MESSAGE.USER_NOT_FOUND_IN_OKTA: {
            displaySnackbar({
              message: t('userInvite.message.notFound'),
              type: 'error',
              timeout: 5000,
            });
            break;
          }
          default: {
            displaySnackbar({
              message: t('userInvite.message.unknown'),
              type: 'error',
            });
            throw apiResponse.message;
          }
        }
        setIsCallingReInviteApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'error');
        // if (isErrorType(error)) {
        //   setUserDeleteErrorType(error);
        // } else {
        //   setUserDeleteErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
        // }
        // loadingBar非表示(finallyで消すとメモリーリークが発生するのでこのタイミングで消す)
        setIsCallingReInviteApi(false);
      });
  };

  return {
    onClickReInviteButton,
    isCallingReInviteApi,
    invitationStatus,
  };
};
export default useUserReinvite;
