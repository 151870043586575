import { useSelector } from 'react-redux';
import { Dialog, Box, Typography, Button } from '@mui/material';
import { useStyles } from './DialogStyle';
import { RootState } from '../../state/store';
import useWelcomeDialog from '../../hooks/useWelcomeDialog';
import welcome from '../../images/welcome.svg';
import { ReactComponent as Icon } from '../../images/icon.svg';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

const WelcomeDialog = (): JSX.Element => {
  const classes = useStyles();

  /*eslint-disable*/
  const welcomeDialog = useSelector(
    (state: RootState) => state.welcomeDialogState,
  );
  const { closeWelcomeDialog } = useWelcomeDialog();

  const handleClose = () => {
    closeWelcomeDialog();
  };
  const { t } = useSwitchLocaleLanguage();

  return (
    <Dialog open={welcomeDialog.open} className={classes.welcomeDialog}>
      <Box className={classes.dialogContainer}>
        <Box
          sx={{
            height: '174px',
            width: '477px',
            margin: '0px 5px 36px',
            '@media screen and (max-width:600px)': {
              margin: '0px 5px 90px',
            },
            '@media screen and (max-width:400px)': {
              margin: '0px 5px 120px',
            },
            backgroundImage: `url(${welcome})`,
          }}
        >
          <Icon className={classes.welcomeLogo} />
          <Typography className={classes.welcomeText}>
            {t('common.label.congratulationAndWelcome')}
          </Typography>
        </Box>
        <Typography className={classes.welcomeTextSecondary}>
          {t('common.label.makeLifeEasier')}
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '16px',
        }}
      >
        <Button
          sx={{
            p: '10px 29px',
            width: '80px',
            height: '44px',
            background: '#009DE1',
            fontSize: '16px',
            fontweight: '500px',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: '#007EB4',
            },
          }}
          disableRipple
          onClick={handleClose}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  );
};
export default WelcomeDialog;
