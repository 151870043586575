import React from 'react';
import { Box, Button, Typography, Skeleton } from '@mui/material';
// import { TFunction } from 'react-i18next';
import { ReactComponent as RegistrationIcon } from '../../images/icon/Registration.svg';
// import Loader from '../Atoms/Loader';
// import { STATUS_COLOR_CODE } from '../../constants/constants';
import { statusColorFn } from '../../utils/devicelist/deviceListUtil';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  status?: string;
  statusColor?: string;
  regDate: string;
  invDate: string;
  isCallingApi?: boolean;
  isCallingReInviteApi?: boolean;
  isButtonAndStatus?: boolean;
  onClickReInviteButton?: () => void;
  reInviteEnable?: boolean;
};
const RegistrationInformationCard = React.memo<Props>(
  ({
    isCallingApi,
    isCallingReInviteApi,
    isButtonAndStatus,
    status,
    regDate,
    invDate,
    onClickReInviteButton,
    statusColor,
    reInviteEnable,
  }): JSX.Element => {
    const { t } = useSwitchLocaleLanguage();

    return (
      <Box
        sx={{
          width: '100%',
          background: '#FFFFFF',
          border: '0.5px solid rgba(217, 217, 217, .5)',
          borderRadius: '8px',
          // height: isButtonAndStatus ? '217px' : '179px',
          padding: '32px',
        }}
        className="user-details-common-card"
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            height: '29px',
            marginBottom: '24px',
          }}
        >
          <Typography
            sx={{
              minWidth: '24px',
            }}
          >
            <RegistrationIcon />
          </Typography>

          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'Medium',
              lineHeight: '20px',
            }}
          >
            {t('personalSetting.label.registrationInfo')}
          </Typography>
        </Box>

        <Box className="reg-info-row">
          {/* status  */}
          {isButtonAndStatus && (
            <Box className="personal-reg-setting">
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'Medium',
                  lineHeight: '20px',
                }}
              >
                {t('personalSetting.label.registrationStatus')}:
              </Typography>
              <Box>
                {isCallingApi ? (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '20px',
                      width: '70px',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      background:
                        statusColor && statusColorFn(statusColor).toString(),
                      borderRadius: '10px',
                      padding: '2px 13px 4px',
                      height: '25px',
                      width: 'fit-content',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        color: '#FFFFFF',
                      }}
                    >
                      {status}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {/* reg date  */}
          {regDate && (
            <Box className="personal-reg-setting">
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'Medium',
                  lineHeight: '20px',
                }}
              >
                {t('personalSetting.label.registrationDate')}:
              </Typography>
              {isCallingApi ? (
                <Box>
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      lineHeight: '20px',
                      width: '70px',
                    }}
                  />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '14px',
                    height: '20px',
                  }}
                >
                  {regDate}
                </Typography>
              )}
            </Box>
          )}
          {/* invitation  */}
          <Box className="personal-reg-setting">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 'Medium',
                lineHeight: '20px',
              }}
            >
              {t('personalSetting.label.invitationDate')}:
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '14px',
                height: '20px',
              }}
            >
              {invDate}
            </Typography>
          </Box>
          {!isCallingApi &&
            isButtonAndStatus &&
            reInviteEnable &&
            status !== t('personalSetting.label.registered') &&
            status !== t('personalSetting.label.suspend') && (
              <Button
                id="userDetailSave"
                type="submit"
                variant="contained"
                sx={{
                  p: '2px',
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: 'Normal',
                  lineHeight: '0px',
                  marginTop: '-6px',
                  background: '#FFFFFF',
                  color: '#009DE1',
                  boxShadow: 0,
                  borderRadius: '6px',
                  '&:hover': {
                    background: '#FFFFFF',
                    color: '#007EB4',
                  },
                  '&:disabled': {
                    color: '#009DE1',
                    background: 'white',
                    opacity: 0.5,
                  },
                }}
                disableRipple
                onClick={(event) => {
                  event.preventDefault();
                  if (onClickReInviteButton) {
                    onClickReInviteButton();
                  }
                }}
                disabled={isCallingReInviteApi}
              >
                {isCallingReInviteApi
                  ? t('personalSetting.button.clickReInviting')
                  : t('personalSetting.button.clickReInvite')}
              </Button>
            )}
        </Box>
      </Box>
    );
  },
);

export default RegistrationInformationCard;
