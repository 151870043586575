import { useAuth0 } from '@auth0/auth0-react';
import { signOut } from 'firebase/auth';
// import callLogOutApi from '../apis/callLogOutApi';
// import useAccessKey from './useAccessKey';
// import useAccountInfo from './useAccountInfo';
import useDrawerInfo from './useDrawerInfo';
import { fireBaseAuth } from '../firebase/firebase';
import useSnackbar from './useSnackbar';

/**
 * 本カスタムフックからの返却値
 */
export type UseLogoutValue = {
  // ［ログアウト］ボタンクリック処理
  onClickLogoff: () => void;
};

/**
 * ログアウト hooks
 *
 * @returns
 */
const useLogout = (): UseLogoutValue => {
  // navigate(画面遷移)
  const { logout } = useAuth0();

  // アクセスキー hooks
  // const { reset } = useAccessKey();
  // // // アカウント情報 hooks
  // const { accountoInfoReset } = useAccountInfo();
  const { closeDrawer } = useDrawerInfo();
  const { displaySnackbar } = useSnackbar();

  const onClickLogoff = (): void => {
    void logout({ logoutParams: { returnTo: window.location.origin } })
      .then(() => {
        // reset();
        // accountoInfoReset();
        // closeDrawer();
        // void callLogOutApi();
        // localStorage.removeItem('OktaToken');
      })
      .catch((err) => {
        console.log(err);
        displaySnackbar({
          message: 'Failed to Logout.',
          timeout: 3001,
        });
      })
      .finally(() => {
        localStorage.removeItem('OktaToken');
        // reset();
        // accountoInfoReset();
      });
    // reset();
    // accountoInfoReset();
    // void callLogOutApi();
    // // Call logOut api

    // eslint-disable-next-line
    signOut(fireBaseAuth)
      .then((resp) => console.log(resp))
      .catch((error) => console.log(error));
    closeDrawer();
  };

  return {
    onClickLogoff,
  };
};

export default useLogout;
