import type {
  TransCountListApiParameter,
  TransCountListApiResponse,
} from '../types/apis/transCountListApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * 翻訳回数取得API 結果コード
 */
export const TRANS_COUNT_LIST_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 表示件数オーバー
  INFO_DATA_OVER: 'INFO_DATA_OVER',
  // アクセスキー無し(※翻訳回数取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※翻訳回数取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※翻訳回数取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',

  INVALID_TOKEN: 'INVALID_TOKEN',
  USER_CONSENT_REQUIRED: 'USER_CONSENT_REQUIRED',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
  NO_INTERNET: 'NO_INTERNET',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  USER_DEACTIVATED : 'USER_DEACTIVATED',
} as const;

/**
 * 翻訳回数取得エラータイプ
 */
export type TRANS_COUNT_LIST_ERROR_TYPE =
  (typeof TRANS_COUNT_LIST_API_RESULT_CODE)[keyof typeof TRANS_COUNT_LIST_API_RESULT_CODE];

/**
 * 翻訳回数取得API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 翻訳回数取得APIからのレスポンス
 */
const callTransCountListApi = (
  requestParams: TransCountListApiParameter,
  // accessKey: string,
): Promise<TransCountListApiResponse> => {
  // リクエストパラメータ
  const jsonParams = {
    corp_id: requestParams.corpId,
    from: requestParams.from,
    to: requestParams.to,
    view: requestParams.view,
  };
  // リクエスト送信(POST)
  const response = callApiUtil
    .get<TransCountListApiResponse>(
      `${API_URL.TRANS_COUNT_LIST_API}?corpId=${jsonParams.corp_id}&from=${jsonParams.from}&to=${jsonParams.to}&view=${jsonParams.view}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callTransCountListApi;
