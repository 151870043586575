import React, { useEffect } from 'react';
import {
  Box,
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  styled,
  CssBaseline,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useLocation } from 'react-router-dom';

// import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import {
  PAGE_PATH_NAME,
  MENU_VALUE,
  MENU_INDEX,
  SUB_MENU_INDEX,
  FEATURE_PERMISSION,
} from '../../constants/constants';
import { theme, useStyles } from '../Templates/CommonStyle';
import useMainMenu from '../../hooks/useMainMenu';
import useAccountInfo from '../../hooks/useAccountInfo';
import { LOCALE_CODE } from '../../hooks/useLocale';

// import images for menu icons
import { ReactComponent as MenuUserIconBlack } from '../../images/icon/MenuUserIconBlack.svg';
import { ReactComponent as MenuUserIconWhite } from '../../images/icon/MenuUserIconWhite.svg';
import { ReactComponent as SecurityIconBlack } from '../../images/icon/security.svg';
import { ReactComponent as SecurityIconWhite } from '../../images/icon/security-white.svg';
import { ReactComponent as DashboardIconBlack } from '../../images/icon/dashboard-black.svg';
import { ReactComponent as DashboardIconWhite } from '../../images/icon/dashboard-white.svg';
import { ReactComponent as DeviceIconBlack } from '../../images/icon/device-black.svg';
import { ReactComponent as DeviceIconWhite } from '../../images/icon/device-white.svg';
import { ReactComponent as ReportsIconBlack } from '../../images/icon/reports-black.svg';
import { ReactComponent as ReportsIconWhite } from '../../images/icon/reports-white.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/icon/right-arrow.svg';
import { ReactComponent as AddIcon } from '../../images/icon/plus.svg';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useRefreshRedux from '../../hooks/useRefreshRedux';
import useGetUpdateUserInfo from '../../hooks/useGetUpdateUserInfo';

const MyListItemButton = styled(ListItemButton)({
  backgroundColor: '#FFFFFF1A',
  color: 'white',
  fontSize: '14px',
  marginBottom: '8px',
  height: '50px',
  minWidth: '50px',
  gap: '12px',
  paddingRight: '14px',
}) as typeof ListItemButton;

const MySubListItemButton = styled(ListItemButton)({
  borderRadius: '6px',
  color: 'white',
  marginBottom: '2px',
  fontSize: '14px',
  paddingLeft: '32px',
  paddingTop: '7px',
  paddingBottom: '7px',
  lineHeight: '20px',
  height: '34px',
  gap: 0,
}) as typeof ListItemButton;

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // ドロワーメニューを閉じる
  closeMainMenu: () => void;
  // [ログデータ]メニューがクリックされた
  onClickLogDataMenu: () => void;
  closeSettingMenu: (value: string) => void;
  isSettingMenuOpen: boolean;
  isDrawerMenuOpen: boolean;
  onClickOpenDrawer: () => void;
};

/**
 * メインメニューコンポーネント
 *
 * @returns
 */
const MainMenu = React.memo<Props>((props): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  const [subMenuIndex, setSubMenuIndex] = React.useState<number>();
  // 共通スタイル
  const classes = useStyles();
  /*eslint-disable*/
  const { pathname } = useLocation();
  const groupName = pathname.split('/')[3];
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // ログインアカウント情報
  const { accountInfo } = useAccountInfo();

  const {
    isReportListMenuOpen,
    // isAccountListMenuOpen,
    isGroupListMenuOpen,
    onClickSubMenu,
    onClickRoute,
    // onClickGroupListMenu,
    expandIcon,
    setIsGroupListMenuOpen,
    setIsAccountListMenuOpen,
    setIsReportListMenuOpen,
    onClickGroupMenu,
  } = useMainMenu();
  const { refreshRedux } = useRefreshRedux();
  // update user info hook
  const { callGetUpdateUserInfo } = useGetUpdateUserInfo();

  const mainMenu = [
    {
      name: t('menuItems.dashboard'),
      value: MENU_VALUE.DASHBOARD,
      link: PAGE_PATH_NAME.DASHBOARD,
      activeIndex: MENU_INDEX.DASHBOARD,
      subMenuOpen: false,
      subMenu: [],
      iconWhite: <DashboardIconWhite />,
      iconBlack: <DashboardIconBlack />,
    },
    {
      name: t('menuItems.deviceManager'),
      value: MENU_VALUE.DEVICE_MANAGER,
      link: '',
      activeIndex: MENU_INDEX.DEVICE_MANAGER,
      subMenuOpen: isGroupListMenuOpen,
      iconWhite: <DeviceIconWhite />,
      iconBlack: <DeviceIconBlack />,
      subMenu: [
        {
          name: t('menuItems.device'),
          value: MENU_VALUE.DEVICE_LIST,
          link: PAGE_PATH_NAME.DEVICE_LIST,
          activeIndex: MENU_INDEX.DEVICE_MANAGER,
          selectedIndex: SUB_MENU_INDEX.DEVICE,
        },
        {
          name: t('menuItems.group'),
          value: MENU_VALUE.GROUPS,
          link: PAGE_PATH_NAME.GROUPS,
          activeIndex: MENU_INDEX.DEVICE_MANAGER,
          selectedIndex: SUB_MENU_INDEX.GROUPS,
        },
      ],
    },

    {
      name: t('menuItems.reports'),
      value: MENU_VALUE.REPORTS,
      link: '',
      activeIndex: MENU_INDEX.REPORTS,
      subMenuOpen: isReportListMenuOpen,
      iconWhite: <ReportsIconWhite />,
      iconBlack: <ReportsIconBlack />,
      subMenu: [
        {
          name: t('transCountList.viewName'),
          value: MENU_VALUE.LANGUAGE_COUNT,
          link: PAGE_PATH_NAME.TRANS_COUNT_LIST,
          activeIndex: MENU_INDEX.REPORTS,
          selectedIndex: SUB_MENU_INDEX.LANGUAGE_COUNT,
        },
        {
          name: t('fromLangList.viewName'),
          value: MENU_VALUE.FROM_LANGUAGE,
          link: PAGE_PATH_NAME.FROM_LANG_LIST,
          activeIndex: MENU_INDEX.REPORTS,
          selectedIndex: SUB_MENU_INDEX.FROM_LANGUAGE,
        },
        {
          name: t('toLangList.viewName'),
          value: MENU_VALUE.TO_LANGUAGE,
          link: PAGE_PATH_NAME.TO_LANG_LIST,
          activeIndex: MENU_INDEX.REPORTS,
          selectedIndex: SUB_MENU_INDEX.TO_LANGUAGE,
        },
        {
          name: t('logDataDownloadDialog.viewName'),
          value: MENU_VALUE.LOG_DATA,
          link: '',
          activeIndex: MENU_INDEX.REPORTS,
          selectedIndex: SUB_MENU_INDEX.LOG_DATA,
        },
      ],
    },
  ];
  const settingMenu = [
    {
      name: t('menuItems.accountSetting'),
      value: MENU_VALUE.USERS,
      link: PAGE_PATH_NAME.USER_LIST,
      activeIndex: MENU_INDEX.USERS,
      subMenuOpen: false,
      iconWhite: <MenuUserIconWhite />,
      iconBlack: <MenuUserIconBlack />,
      subMenu: [],
    },
    {
      name: t('menuItems.security'),
      value: MENU_VALUE.SECURITY,
      link: PAGE_PATH_NAME.SECURITY_SETTINGS,
      activeIndex: MENU_INDEX.SECURITY,
      subMenuOpen: false,
      iconWhite: <SecurityIconWhite />,
      iconBlack: <SecurityIconBlack />,
      subMenu: [],
    },
  ];
  const routePath = window.location.pathname;

  useEffect(() => {
    switch (routePath) {
      case PAGE_PATH_NAME.DASHBOARD:
        props.closeSettingMenu('back');
        setSelectedIndex(0);
        setSubMenuIndex(0);
        break;
      case PAGE_PATH_NAME.DASHBOARD:
        setIsGroupListMenuOpen(false);
        setIsAccountListMenuOpen(false);
        setIsReportListMenuOpen(false);
        setSelectedIndex(0);
        setSubMenuIndex(0);
        props.closeSettingMenu('back');
        break;
      case PAGE_PATH_NAME.DEVICE_LIST:
        setSelectedIndex(1);
        setSubMenuIndex(8);
        props.closeSettingMenu('back');
        break;
      case PAGE_PATH_NAME.DEVICE_DETAILS:
        setSelectedIndex(1);
        setSubMenuIndex(8);
        props.closeSettingMenu('back');
        break;
      case PAGE_PATH_NAME.GROUPS:
        setSelectedIndex(1);
        setSubMenuIndex(7);
        setIsReportListMenuOpen(false);
        setIsGroupListMenuOpen(true);
        props.closeSettingMenu('back');

        break;
      case PAGE_PATH_NAME.ADD_GROUP:
        setSelectedIndex(1);
        setSubMenuIndex(7);
        setIsGroupListMenuOpen(true);
        setIsReportListMenuOpen(false);
        setIsAccountListMenuOpen(false);
        props.closeSettingMenu('back');
        break;
      case `/groups/groupdetail/${groupName}`:
        setSelectedIndex(1);
        setSubMenuIndex(7);
        setIsGroupListMenuOpen(true);
        setIsAccountListMenuOpen(false);
        setIsReportListMenuOpen(false);
        props.closeSettingMenu('back');

        break;

      case PAGE_PATH_NAME.TRANS_COUNT_LIST:
        setSelectedIndex(2);
        setSubMenuIndex(1);
        setIsReportListMenuOpen(true);
        setIsGroupListMenuOpen(false);

        break;
      case PAGE_PATH_NAME.FROM_LANG_LIST:
        setSelectedIndex(2);
        setSubMenuIndex(2);
        setIsReportListMenuOpen(true);
        setIsGroupListMenuOpen(false);

        props.closeSettingMenu('back');

        setSelectedIndex(2);
        setSubMenuIndex(2);
        setIsReportListMenuOpen(true);
        setIsGroupListMenuOpen(false);

        props.closeSettingMenu('back');
        break;
      case PAGE_PATH_NAME.TO_LANG_LIST:
        setSelectedIndex(2);
        setSubMenuIndex(3);
        setIsReportListMenuOpen(true);
        setIsGroupListMenuOpen(false);
        props.closeSettingMenu('back');

        break;
      case PAGE_PATH_NAME.USER_LIST:
        setSelectedIndex(3);
        setSubMenuIndex(5);
        setIsGroupListMenuOpen(false);
        onClickGroupMenu();
        props.closeSettingMenu('setting');
        break;
      case PAGE_PATH_NAME.USER_DETAIL:
        setSelectedIndex(3);
        setSubMenuIndex(5);
        setIsGroupListMenuOpen(false);
        props.closeSettingMenu('setting');
        break;
      case PAGE_PATH_NAME.DEVICE:
        setSelectedIndex(4);
        setSubMenuIndex(8);
        props.closeSettingMenu('setting');
        break;
      case PAGE_PATH_NAME.REMOTE_SETTINGS:
        setSelectedIndex(4);
        setSubMenuIndex(6);
        props.closeSettingMenu('setting');
        break;

      case `/personalsetting`:
        setSelectedIndex(10);
        setSubMenuIndex(10);
        setIsGroupListMenuOpen(false);
        setIsAccountListMenuOpen(false);
        setIsReportListMenuOpen(false);
        break;
      case `/corporateprofile`:
        setSelectedIndex(10);
        setSubMenuIndex(10);
        setIsGroupListMenuOpen(false);
        setIsAccountListMenuOpen(false);
        setIsReportListMenuOpen(false);
        break;
      case PAGE_PATH_NAME.SECURITY_SETTINGS:
        setSelectedIndex(4);
        setSubMenuIndex(0);
        setIsGroupListMenuOpen(false);
        props.closeSettingMenu('setting');
        break;
      default:
        break;
    }
    /* eslint-disable */
  }, [routePath, selectedIndex, subMenuIndex, props.isSettingMenuOpen]);

  const checkReportSubmenuPermission = (subMenu: string) => {
    if (
      subMenu === MENU_VALUE.LANGUAGE_COUNT &&
      !accountInfo?.featurePermissions?.REPORTING?.TRANSLATIONCOUNT?.includes(
        FEATURE_PERMISSION.REPORTING.TRANSLATIONCOUNT.VIEWLIST,
      )
    ) {
      return false;
    }
    if (
      subMenu === MENU_VALUE.FROM_LANGUAGE &&
      !accountInfo?.featurePermissions?.REPORTING?.TRANSLATIONCOUNTFROM?.includes(
        FEATURE_PERMISSION.REPORTING.TRANSLATIONCOUNTFROM.VIEWLIST,
      )
    ) {
      return false;
    }
    if (
      subMenu === MENU_VALUE.TO_LANGUAGE &&
      !accountInfo?.featurePermissions?.REPORTING?.TRANSLATIONCOUNTTO?.includes(
        FEATURE_PERMISSION.REPORTING.TRANSLATIONCOUNTTO.VIEWLIST,
      )
    ) {
      return false;
    }

    if (
      subMenu === MENU_VALUE.LOG_DATA &&
      !accountInfo?.featurePermissions?.REPORTING?.LOGDATA?.includes(
        FEATURE_PERMISSION.REPORTING.LOGDATA.EXPORT,
      )
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (selectedIndex === 0) {
      setIsGroupListMenuOpen(false);
      setIsReportListMenuOpen(false);
    }
    if (selectedIndex === 1) {
      setIsReportListMenuOpen(false);
      setIsGroupListMenuOpen(true);
    }
    if (selectedIndex === 2) {
      setIsReportListMenuOpen(true);
      setIsGroupListMenuOpen(false);
    }
    if (selectedIndex === 3) {
      setIsReportListMenuOpen(false);
      setIsGroupListMenuOpen(false);
    }
  }, [selectedIndex]);

  return (
    <List className={classes.listItemSidebar}>
      <CssBaseline />
      <div className={classes.drawerList}>
        {props.isSettingMenuOpen ? (
          <div>
            <Box sx={{ textAlign: 'left', mb: '22px' }}>
              <Button
                className={classes.backButton}
                size="small"
                disableRipple
                startIcon={<ArrowRightIcon />}
                onClick={() => {
                  props.closeSettingMenu('back');
                  onClickRoute(MENU_VALUE.DASHBOARD);
                }}
              >
                {!props.isDrawerMenuOpen ? t('menuItems.back') : ''}
              </Button>
              <Typography
                className={classes.menuTitle}
                sx={{
                  visibility: !props.isDrawerMenuOpen ? 'visible' : 'hidden',
                }}
              >
                {t('menuItems.setting')}
              </Typography>
            </Box>
            {settingMenu.map((menu) => {
              if (
                menu.name === t('menuItems.accountSetting') &&
                !accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
                  FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.VIEWLIST,
                )
              ) {
                return;
              }
              if (
                menu.name === t('menuItems.security') &&
                !accountInfo.featurePermissions?.SECURITYMANAGEMENT
              ) {
                return;
              }

              return (
                <div key={menu.activeIndex}>
                  <MyListItemButton
                    sx={{
                      backgroundColor:
                        selectedIndex === menu.activeIndex
                          ? '#FFD514 !important'
                          : '',
                      color:
                        selectedIndex === menu.activeIndex
                          ? '#313133 !important'
                          : '',
                    }}
                    onClick={() => {
                      onClickRoute(menu.value);
                      // onClickGroupMenu();
                      onClickSubMenu(menu.value);
                      setSelectedIndex(
                        menu.subMenu.length ? selectedIndex : menu.activeIndex,
                      );
                      setSubMenuIndex(menu.subMenu.length === 0 ? 0 : 1);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: props.isDrawerMenuOpen ? 3 : 'auto',
                        justifyContent: 'center',
                        color:
                          selectedIndex === menu.activeIndex
                            ? '#313133 !important'
                            : 'white',
                      }}
                    >
                      {selectedIndex === menu.activeIndex
                        ? menu.iconBlack
                        : menu.iconWhite}
                    </ListItemIcon>
                    <ListItemText
                      color="common.mainYellow"
                      primary={menu.name}
                      disableTypography
                      sx={{
                        opacity: props.isDrawerMenuOpen ? 0 : 1,
                      }}
                      onClick={() => onClickRoute(menu.name)}
                    />
                    {menu.subMenu.length > 0 &&
                      expandIcon(
                        menu.subMenu,
                        menu.subMenuOpen,
                        selectedIndex === menu.activeIndex,
                      )}
                  </MyListItemButton>
                  <Collapse
                    in={menu.subMenuOpen && !props.isDrawerMenuOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      className={classes.sidebarSubmenu}
                    ></List>
                  </Collapse>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {mainMenu.map((menu) => {
              if (
                !accountInfo?.featurePermissions?.REPORTING &&
                menu.name === MENU_VALUE.REPORTS
              ) {
                return;
              }
              if (
                menu.name === t('menuItems.deviceManager') &&
                !(
                  accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.includes(
                    FEATURE_PERMISSION.GROUPMANAGEMENT?.GROUPBASIC.VIEWLIST,
                  ) ||
                  accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEBASIC?.includes(
                    FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEBASIC.VIEWLIST,
                  )
                )
              ) {
                return;
              }

              return (
                <div key={menu.activeIndex}>
                  <MyListItemButton
                    selected={selectedIndex === menu.activeIndex}
                    sx={{
                      backgroundColor:
                        selectedIndex === menu.activeIndex
                          ? '#FFD514 !important'
                          : '',
                      color:
                        selectedIndex === menu.activeIndex
                          ? '#313133 !important'
                          : '',
                    }}
                    disableRipple
                    onClick={() => {
                      onClickRoute(menu.value);
                      setSelectedIndex(
                        menu.subMenu.length ? selectedIndex : menu.activeIndex,
                      );
                      onClickSubMenu(menu.value);
                      setSubMenuIndex(
                        menu.subMenu.length === 0 ? 0 : subMenuIndex,
                      );
                      props.onClickOpenDrawer();
                    }}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      sx={{
                        mr: props.isDrawerMenuOpen ? 3 : 'auto',
                        color:
                          selectedIndex === menu.activeIndex
                            ? '#313133 !important'
                            : 'white',
                        minWidth: props.isDrawerMenuOpen ? '20px' : '14px',
                      }}
                    >
                      {selectedIndex === menu.activeIndex
                        ? menu.iconBlack
                        : menu.iconWhite}
                    </ListItemIcon>
                    <ListItemText
                      color="common.mainYellow"
                      primary={menu.name}
                      disableTypography
                      sx={{
                        opacity: props.isDrawerMenuOpen ? 0 : 1,
                        overflow: 'hidden',
                        whiteSpace: 'initial',
                        maxWidth:
                          menu.name === t('menuItems.deviceManager')
                            ? '126px'
                            : 'unset',
                      }}
                    />
                    {menu.subMenu.length > 0 &&
                      expandIcon(
                        menu.subMenu,
                        menu.subMenuOpen,
                        selectedIndex === menu.activeIndex,
                      )}
                  </MyListItemButton>
                  <Collapse
                    in={menu.subMenuOpen && !props.isDrawerMenuOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      className={classes.sidebarSubmenu}
                    >
                      {menu.subMenu.map((sub) => {
                        if (!checkReportSubmenuPermission(sub.value)) {
                          return;
                        }
                        return sub.value === MENU_VALUE.GROUPS ? (
                          accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.includes(
                            FEATURE_PERMISSION.GROUPMANAGEMENT?.GROUPBASIC
                              .VIEWLIST,
                          ) && (
                            <div key={sub.selectedIndex}>
                              <MySubListItemButton
                                sx={{
                                  backgroundColor:
                                    subMenuIndex === sub.selectedIndex &&
                                    selectedIndex === sub.activeIndex
                                      ? '#3131331A'
                                      : '',
                                  color:
                                    subMenuIndex === sub.selectedIndex &&
                                    selectedIndex === sub.activeIndex
                                      ? '#FFD514'
                                      : '',
                                }}
                                onClick={() => {
                                  // setSelectedIndex(sub.activeIndex);
                                  // setSubMenuIndex(sub.selectedIndex);
                                  onClickRoute(MENU_VALUE.GROUPS);
                                }}
                                key={sub.selectedIndex}
                              >
                                <ListItemIcon
                                  className={classes.subMenuIcon}
                                  sx={{
                                    color:
                                      subMenuIndex === sub.selectedIndex &&
                                      selectedIndex === sub.activeIndex
                                        ? '#FFD514'
                                        : 'white',
                                  }}
                                >
                                  <ArrowRightIcon
                                    className={`${classes.collapseMenuIcon} ${
                                      subMenuIndex === sub.selectedIndex &&
                                      selectedIndex === sub.activeIndex
                                        ? classes.collapseMenuIconActive
                                        : ''
                                    }`}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={sub.name}
                                  disableTypography
                                  // onClick={() => {
                                  //   onClickGroupListMenu();
                                  // }}
                                />
                                {accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.filter(
                                  (x) =>
                                    x ===
                                    FEATURE_PERMISSION.GROUPMANAGEMENT
                                      .GROUPBASIC.ADD,
                                ).length === 1 && (
                                  <Tooltip
                                    color={theme.palette.common.black}
                                    PopperProps={{
                                      modifiers: [
                                        {
                                          name: 'offset',
                                          options: {
                                            offset: [0, -4],
                                          },
                                        },
                                      ],
                                    }}
                                    title={
                                      t('common.tooltip.createGroup') as string
                                    }
                                    arrow
                                  >
                                    <ListItemIcon
                                      className={classes.createGroupBtn}
                                      sx={{
                                        mr: props.isDrawerMenuOpen ? 3 : 'auto',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        refreshRedux();
                                        callGetUpdateUserInfo();
                                        onClickRoute(MENU_VALUE.ADD_GROUP);
                                        sendGAEvent(
                                          'menu_create_group_btn',
                                          'btn_name',
                                          'menu_create_group',
                                        );
                                      }}
                                    >
                                      <AddIcon />
                                    </ListItemIcon>
                                  </Tooltip>
                                )}
                              </MySubListItemButton>
                            </div>
                          )
                        ) : (
                          <div key={sub.selectedIndex}>
                            {sub.value === MENU_VALUE.LOG_DATA ? (
                              accountInfo.featurePermissions?.REPORTING.LOGDATA?.includes(
                                FEATURE_PERMISSION.REPORTING.LOGDATA.EXPORT,
                              ) && (
                                <MySubListItemButton
                                  sx={{
                                    backgroundColor:
                                      subMenuIndex === sub.selectedIndex &&
                                      selectedIndex === sub.activeIndex
                                        ? '#3131331A'
                                        : '',
                                    color:
                                      subMenuIndex === sub.selectedIndex &&
                                      selectedIndex === sub.activeIndex
                                        ? '#FFD514'
                                        : '',
                                    height:
                                      accountInfo.locale === LOCALE_CODE.JA ||
                                      accountInfo.locale === LOCALE_CODE.FR
                                        ? '50px'
                                        : '',
                                  }}
                                  onClick={() => {
                                    // setSelectedIndex(sub.activeIndex);
                                    // setSubMenuIndex(sub.selectedIndex);
                                    props.onClickLogDataMenu();

                                    // GA tag
                                    sendGAEvent(
                                      'press_btn',
                                      'btn_name',
                                      'export_log_data_menu',
                                    );
                                  }}
                                >
                                  <ListItemIcon
                                    className={classes.subMenuIcon}
                                    sx={{
                                      color:
                                        subMenuIndex === sub.selectedIndex &&
                                        selectedIndex === sub.activeIndex
                                          ? '#FFD514'
                                          : 'white',
                                    }}
                                  >
                                    <ArrowRightIcon
                                      className={`${classes.collapseMenuIcon} ${
                                        subMenuIndex === sub.selectedIndex &&
                                        selectedIndex === sub.activeIndex
                                          ? classes.collapseMenuIconActive
                                          : ''
                                      }`}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    className={classes.listItemText}
                                    sx={{
                                      whiteSpace:
                                        accountInfo.locale === LOCALE_CODE.JA ||
                                        accountInfo.locale === LOCALE_CODE.FR
                                          ? 'pre-wrap'
                                          : '',
                                    }}
                                    primary={sub.name}
                                    disableTypography
                                  />
                                </MySubListItemButton>
                              )
                            ) : (
                              <MySubListItemButton
                                sx={{
                                  backgroundColor:
                                    subMenuIndex === sub.selectedIndex &&
                                    selectedIndex === sub.activeIndex
                                      ? '#3131331A'
                                      : '',
                                  color:
                                    subMenuIndex === sub.selectedIndex &&
                                    selectedIndex === sub.activeIndex
                                      ? '#FFD514'
                                      : '',
                                  height:
                                    sub.value === MENU_VALUE.LANGUAGE_COUNT &&
                                    accountInfo.locale === LOCALE_CODE.DE
                                      ? '50px'
                                      : '34px',
                                }}
                                onClick={() => {
                                  onClickRoute(sub.value);
                                  // setSelectedIndex(sub.activeIndex);
                                  // setSubMenuIndex(sub.selectedIndex);
                                }}
                              >
                                <ListItemIcon
                                  className={classes.subMenuIcon}
                                  sx={{
                                    color:
                                      subMenuIndex === sub.selectedIndex &&
                                      selectedIndex === sub.activeIndex
                                        ? '#FFD514'
                                        : 'white',
                                  }}
                                >
                                  <ArrowRightIcon
                                    className={`${classes.collapseMenuIcon} ${
                                      subMenuIndex === sub.selectedIndex &&
                                      selectedIndex === sub.activeIndex
                                        ? classes.collapseMenuIconActive
                                        : ''
                                    }`}
                                  />
                                </ListItemIcon>

                                <ListItemText
                                  className={classes.listItemText}
                                  sx={{
                                    whiteSpace:
                                      sub.value === MENU_VALUE.LANGUAGE_COUNT &&
                                      accountInfo.locale === LOCALE_CODE.DE
                                        ? 'pre-wrap'
                                        : '',
                                  }}
                                  primary={sub.name}
                                  disableTypography
                                />
                              </MySubListItemButton>
                            )}
                          </div>
                        );
                      })}
                    </List>
                  </Collapse>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </List>
  );
});

export default MainMenu;
