import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import store from './state/store';
// import mockServiceWorker from './mocks/browser';
import '@fontsource/noto-sans';
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans-jp';
import '@fontsource/noto-sans-jp/300.css';
import '@fontsource/noto-sans-jp/500.css';

// mock起動
// if (process.env.NODE_ENV === 'development') {
//   void mockServiceWorker.start();
// }

// ReduxフレームワークのStore利用準備
const persistor = persistStore(store);

const onRedirectCallBack = (appState: any) => {
  console.log(appState);
};

ReactDOM.render(
  <Auth0Provider
    domain= {process.env.REACT_APP_AUTH0_DOMAIN ?? "crown-dev.jp.auth0.com"} 
    clientId= {process.env.REACT_APP_AUTH0_CLIENT_ID ?? "5hNVQeNHBjgDqmuhRwYN3rZix44KKTM8"}
    authorizationParams={{
      audience: process.env.REACT_APP_AUTH0_AUDIENC ??'https://api.pocketalk.com',
      redirect_uri: window.location.origin,
      // scope: 'openid profile email offline_access read:current_user',
    }}
    // useRefreshTokens={true}
    // cacheLocation="localstorage"
    onRedirectCallback={onRedirectCallBack}
  >
    <HttpsRedirect>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </HttpsRedirect>
  </Auth0Provider>,
  document.getElementById('root'),
);
