import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { translationsOptionsObject } from '../dashboardConstants';

dayjs.extend(weekday);

export const thisWeekRange = () => {
  const firstDay = dayjs().weekday(0).format('YYYYMMDD');
  const lastDay = dayjs().weekday(6).format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const previousWeekRange = () => {
  const firstDay = dayjs().weekday(-7).format('YYYYMMDD');
  const lastDay = dayjs().weekday(-1).format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const thisMonthRange = () => {
  const firstDay = dayjs().startOf('month').format('YYYYMMDD');
  const lastDay = dayjs().endOf('month').format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const previousMonthRange = () => {
  const firstDay = dayjs()
    .month(dayjs().get('month') - 1)
    .format('YYYYMM01');
  const totalDaysInMonth = dayjs()
    .month(dayjs().get('month') - 1)
    .daysInMonth();
  const lastDay = dayjs()
    .month(dayjs().get('month') - 1)
    .format(`YYYYMM${totalDaysInMonth}`);

  return { firstDay, lastDay };
};

export const thisYearRange = () => {
  const firstDay = dayjs().format('YYYY0101');
  const lastDay = dayjs().format('YYYY1231');

  return { firstDay, lastDay };
};

export const previousYearRange = () => {
  const previousYear = dayjs().year() - 1;
  const firstDay = `${previousYear}0101`;
  const lastDay = `${previousYear}1231`;

  return { firstDay, lastDay };
};

export const all = () => {
  const firstDay = dayjs().format('19700101');
  const lastDay = dayjs().format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const dateRangeCalculator = (val: string) => {
  switch (val) {
    case translationsOptionsObject.ALL:
      return all();
    case translationsOptionsObject.PREVIOUS_WEEK:
      return previousWeekRange();
    case translationsOptionsObject.THIS_WEEK:
      return thisWeekRange();
    case translationsOptionsObject.PREVIOUS_MONTH:
      return previousMonthRange();
    case translationsOptionsObject.THIS_MONTH:
      return thisMonthRange();
    case translationsOptionsObject.PREVIOUS_YEAR:
      return previousYearRange();
    case translationsOptionsObject.THIS_YEAR:
      return thisYearRange();

    default:
      return thisWeekRange();
  }
};

const isLeapYear = (year: number): boolean => {
  if (year % 4 !== 0) {
    return false;
  }
  if (year % 100 !== 0) {
    return true;
  }
  if (year % 400 !== 0) {
    return false;
  }

  return true;
};

export const dateLabelList = (
  dateRange:
    | {
        firstDay: string;
        lastDay: string;
      }
    | undefined,
) => {
  let firstYear = Number(dateRange?.firstDay.substring(0, 4));
  let firstMonth = Number(dateRange?.firstDay.substring(4, 6));
  let firstDate = Number(dateRange?.firstDay.substring(6, 8));
  let firstDateInNumber = Number(dateRange?.firstDay);

  // const lastYear = Number(dateRange?.lastDay.substring(0, 4));
  const lastMonth = Number(dateRange?.lastDay.substring(4, 6));
  // const lastDate = Number(dateRange?.lastDay.substring(6, 8));
  const lastDateInNumber = Number(dateRange?.lastDay);

  const dateList = [];

  if(lastMonth - firstMonth === 11){
    dateList.push("0");
    dateList.push("1");
    dateList.push("2");
    dateList.push("3");
    dateList.push("4");
    dateList.push("5");
    dateList.push("6");
    dateList.push("7");
    dateList.push("8");
    dateList.push("9");
    dateList.push("10");
    dateList.push("11");

    return dateList;
  }

  while (firstDateInNumber <= lastDateInNumber) {
    dateList.push(
      `${firstYear}-${firstMonth < 10 ? '0' : ''}${firstMonth}-${
        firstDate < 10 ? '0' : ''
      }${firstDate}`,
    );

    if (
      firstMonth === 1 ||
      firstMonth === 3 ||
      firstMonth === 5 ||
      firstMonth === 7 ||
      firstMonth === 8 ||
      firstMonth === 10 ||
      firstMonth === 12
    ) {
      if (firstDate === 31) {
        if (firstMonth === 12) {
          firstMonth = 1;
          firstYear += 1;
          firstDateInNumber = firstYear * 10000 + firstMonth * 100;
        } else {
          firstMonth += 1;
          firstDateInNumber += 69;
        }
        firstDate = 0;
      }
    } else if (
      firstMonth === 4 ||
      firstMonth === 6 ||
      firstMonth === 9 ||
      firstMonth === 11
    ) {
      if (firstDate === 30) {
        firstMonth += 1;
        firstDateInNumber += 70;
        firstDate = 0;
      }
    } else if (firstMonth === 2) {
      if (isLeapYear(firstYear) && firstDate === 29) {
        firstMonth += 1;
        firstDateInNumber += 71;
        firstDate = 0;
      } else if (!isLeapYear(firstYear) && firstDate === 28) {
        firstMonth += 1;
        firstDateInNumber += 72;
        firstDate = 0;
      }
    }

    firstDateInNumber += 1;
    firstDate += 1;
  }

  return dateList;
};

export const getDayName = (dateString: string): number => {
  const date = new Date(dateString);
  const days = [0, 1, 2, 3, 4, 5, 6];

  return days[date.getUTCDay()];
};
