/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Skeleton,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import PersonalSettingsTemplate from '../Templates/PersonalSettingsTemplate';
import { useStyles } from '../Templates/CommonStyle';
import Loader from '../Atoms/Loader';
import useCorporateProfile from '../../hooks/useCorporateProfile';
import { ReactComponent as RightWhite } from '../../images/icon/Right_white.svg';
import { ReactComponent as ContactInfoIcon } from '../../images/icon/vuesax-linear-card.svg';
import { ReactComponent as CorportateIcon } from '../../images/icon/corportate.svg';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as OrderHistory } from '../../images/icon/order_history.svg';
import { ReactComponent as PaymentInfo } from '../../images/icon/payment_info.svg';
import CorporatePageBtnCard from '../Atoms/CorporatePageBtnCard';
import CorporatePageSubscriptionCard from '../Atoms/CorporatePageSubscriptionCard';
import {
  EMAIL_CHECK_PATTERN,
  FEATURE_PERMISSION,
  PAGE_PATH_NAME,
  ZIP_CODE_PATTERN,
} from '../../constants/constants';
import useAccountInfo from '../../hooks/useAccountInfo';

const CorporateProfilePage = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accountInfo } = useAccountInfo();

  // custom hook
  const {
    isSaveBtnEnabled,
    setIsSaveBtnEnabled,
    onClickSaveBtn,
    onClickSubscriptionBtn,
    onClickOrderHistoryBtn,
    onClickPaymentInfoBtn,
    contactInfo,
    isCallingApi,
    isCallingUpdateApi,
    t,
    subscriptionInfo,
  } = useCorporateProfile();

  // start form
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    trigger,
    // clearErrors,
  } = useForm({
    criteriaMode: 'all',
    shouldFocusError: false,
    mode: 'onChange' || 'onSubmit',
  });
  useEffect(() => {
    setValue('corporateName', contactInfo.corporateName);
    setValue('email', contactInfo.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo]);

  const newCorporateName = watch('corporateName');
  const newEmail = watch('email');

  // const [isSaveBtnEnabled, setIsSaveBtnEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      newCorporateName?.trim().length > 0 &&
      newEmail &&
      !errors.corporateName &&
      !errors.email &&
      (newCorporateName?.trim() !== contactInfo.corporateName ||
        newEmail?.trim() !== contactInfo.email)
    ) {
      setIsSaveBtnEnabled(true);
    } else {
      setIsSaveBtnEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactInfo,
    newCorporateName,
    newEmail,
    errors.corporateName,
    errors.email,
  ]);

  // start validate
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  const validateCorporateName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('corporateProfile.validation.nameCharLimit');
    }

    return true;
  };
  const [isValidateCorporateName, setIsValidateCorporateName] = useState(false);
  const validateEmail = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      setIsValidateCorporateName(true);

      return t('common.validationError.email.maxLength');
    }
    setIsValidateCorporateName(false);

    return true;
  };

  const ViewAccess =
    accountInfo.featurePermissions.CORPORATEMANAGEMENT?.CORPORATEPROFILE?.includes(
      FEATURE_PERMISSION.CORPORATEMANAGEMENT.CORPORATEPROFILE.VIEWDETAILS,
    );
  const NoEditAccess =
    !accountInfo.featurePermissions.CORPORATEMANAGEMENT?.CORPORATEPROFILE?.includes(
      FEATURE_PERMISSION.CORPORATEMANAGEMENT.CORPORATEPROFILE.EDIT,
    );
  useEffect(() => {
    // eslint-disable-next-line
    if (!ViewAccess) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [
    accountInfo.featurePermissions.CORPORATEMANAGEMENT?.CORPORATEPROFILE,
    pathname,
  ]);

  // start Close Icon Handle
  // start corporate name Field close Icon
  const [activateCorporateNameCloseIcon, setActivateCorporateNameCloseIcon] =
    useState(false);
  const [isFocusedCorporateName, setIsFocusedCorporateName] = useState(false);

  const handleActivateCorporateNameCloseIcon = () => {
    if (newCorporateName?.length !== 0) {
      setActivateCorporateNameCloseIcon(true);
    }
  };

  const handleDeactivateCorporateNameCloseIcon = () => {
    if (!isFocusedCorporateName) {
      setActivateCorporateNameCloseIcon(false);
    }
  };
  const handleCorporateNameFocus = () => {
    setIsFocusedCorporateName(true);
  };

  const handleCorporateNameBlur = () => {
    setIsFocusedCorporateName(false);
    setActivateCorporateNameCloseIcon(false);
  };

  const [activateEmailCloseIcon, setActivateEmailCloseIcon] = useState(false);
  const [isFocusedEmail, setIsFocusedEmail] = useState(false);

  const handleActivateEmailCloseIcon = () => {
    if (newEmail?.length > 0 && !NoEditAccess) {
      setActivateEmailCloseIcon(true);
    }
  };

  const handleDeactivateEmailCloseIcon = () => {
    if (!isFocusedEmail) {
      setActivateEmailCloseIcon(false);
    }
  };
  const handleEmailFocus = () => {
    setIsFocusedEmail(true);
  };

  const handleEmailBlur = () => {
    setIsFocusedEmail(false);
    setActivateEmailCloseIcon(false);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <PersonalSettingsTemplate titleText={t('corporateProfile.viewName')}>
      <Box sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onClickSaveBtn)}>
          <Box className={classes.personalSettingBtnCard}>
            <Button
              id="userDetailSave"
              type="submit"
              variant="contained"
              className={classes.personalSettingBtn}
              disableRipple
              disabled={isCallingApi || !isSaveBtnEnabled || isCallingUpdateApi}
            >
              {isCallingUpdateApi ? (
                <Loader colorCode="#fff" />
              ) : (
                <RightWhite />
              )}

              <Typography className={classes.personalSettingBtnText}>
                {t('corporateProfile.button.save')}
              </Typography>
            </Button>
          </Box>
          <Box
            className={`${classes.personalSettingPageDivider} corporate-profile-row`}
          >
            <Box className="corporate-profile-col">
              <Box
                className={classes.corporateProfilePageCard}
                sx={{
                  height: '181px',
                  // maxWidth: '780px',
                }}
              >
                <Box className={classes.corporateProfilePageCardHeader}>
                  <CorportateIcon />
                  <Typography
                    className={classes.corporateProfilePageCardHeaderTitle}
                  >
                    {t('corporateProfile.label.corporateInfo')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography className={classes.inputLevelCorporateName}>
                    {t('corporateProfile.label.corporateName')}
                  </Typography>
                  <Typography className={classes.loginCardLevelMarker}>
                    *
                  </Typography>
                </Box>
                {isCallingApi && (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '38px',
                      marginTop: '6px',
                      marginBottom: '8px',
                    }}
                  />
                )}
                {!isCallingApi && (
                  <Box
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Controller
                      control={control}
                      name="corporateName"
                      rules={{
                        required: t('common.validationError.required'),
                        validate: validateCorporateName,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          value={field.value || ''}
                          fullWidth
                          margin="normal"
                          placeholder={t(
                            'corporateProfile.placeholder.corporate',
                          )}
                          autoComplete="off"
                          error={Boolean(errors.corporateName)}
                          onFocus={handleCorporateNameFocus}
                          onBlur={handleCorporateNameBlur}
                          onMouseOver={handleActivateCorporateNameCloseIcon}
                          onMouseLeave={handleDeactivateCorporateNameCloseIcon}
                          inputProps={{
                            maxLength: 257,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  marginLeft: '0px',
                                  width: '20px',
                                }}
                              >
                                <IconButton
                                  aria-label="close"
                                  onClick={() => {
                                    setValue('corporateName', '');
                                    void trigger('corporateName');
                                    setActivateCorporateNameCloseIcon(false);
                                  }}
                                  onMouseDown={handleMouseDown}
                                  edge="end"
                                  sx={{
                                    p: '8px',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                    display: activateCorporateNameCloseIcon
                                      ? ''
                                      : 'none',
                                  }}
                                  disableRipple
                                >
                                  <CloseIcon
                                    style={{
                                      width: '9.01px',
                                      height: '9.01px',
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.corporateName
                                ? '#E1341E !important'
                                : '#939598 !important',
                            },
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '6px',
                              '&.Mui-focused fieldset': {
                                border: errors.corporateName
                                  ? '1px solid #E1341E !important'
                                  : '1px solid #939598 !important',
                              },
                            },
                          }}
                          className={classes.loginFormInput}
                          onChange={(event) => {
                            // handleOnChangeCorporateName(event.target.value);
                            field.onChange(event);
                            if (event.target.value.length !== 0) {
                              setActivateCorporateNameCloseIcon(true);
                            } else {
                              setActivateCorporateNameCloseIcon(false);
                            }
                          }}
                          disabled={NoEditAccess}
                        />
                      )}
                    />

                    {errors.corporateName && (
                      <Box className={classes.userNameError}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {errors.corporateName && errors.corporateName.message}
                        </p>
                      </Box>
                    )}
                  </Box>
                  // </ClickAwayListener>
                )}
              </Box>
              <Box
                className={classes.corporateProfilePageCard}
                sx={{
                  // height: '417px',
                  // maxWidth: '780px',
                  marginTop: '20px',
                }}
              >
                <Box
                  sx={{ marginBottom: '20px !important' }}
                  className={classes.corporateProfilePageCardHeader}
                >
                  <ContactInfoIcon />
                  <Typography
                    className={classes.corporateProfilePageCardHeaderTitle}
                  >
                    {t('corporateProfile.label.contactInformation')}
                  </Typography>
                </Box>
                <Box className="corporate-input-row">
                  <Box className="corporate-input-col">
                    <Box sx={{ display: 'flex' }}>
                      <Typography className={classes.inputLevel}>
                        {t('corporateProfile.label.email')}
                      </Typography>
                      <Typography
                        sx={{ marginTop: '4px' }}
                        className={classes.loginCardLevelMarker}
                      >
                        *
                      </Typography>
                    </Box>
                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <Controller
                          control={control}
                          name="email"
                          rules={{
                            required: t('common.validationError.required'),
                            pattern: {
                              value: EMAIL_CHECK_PATTERN,
                              message: t(
                                'common.validationError.email.invalid',
                              ),
                            },
                            validate: validateEmail,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              disabled={NoEditAccess}
                              value={field.value || ''}
                              fullWidth
                              margin="normal"
                              placeholder={t(
                                'corporateProfile.placeholder.email',
                              )}
                              autoComplete="off"
                              error={Boolean(errors.email)}
                              onFocus={handleEmailFocus}
                              onBlur={handleEmailBlur}
                              onMouseOver={handleActivateEmailCloseIcon}
                              onMouseLeave={handleDeactivateEmailCloseIcon}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{
                                      marginLeft: '0px',
                                      width: '20px',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => {
                                        setValue('email', '');
                                        void trigger('email');
                                        setActivateEmailCloseIcon(false);
                                      }}
                                      onMouseDown={handleMouseDown}
                                      edge="end"
                                      sx={{
                                        p: '8px',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(0, 0, 0, 0.04)',
                                        },
                                        display: activateEmailCloseIcon
                                          ? ''
                                          : 'none',
                                      }}
                                      disableRipple
                                    >
                                      <CloseIcon
                                        style={{
                                          width: '9.01px',
                                          height: '9.01px',
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: errors.email
                                    ? '#E1341E !important'
                                    : '#939598 !important',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '6px',
                                  '&.Mui-focused fieldset': {
                                    border: errors.email
                                      ? '1px solid #E1341E !important'
                                      : '1px solid #939598 !important',
                                  },
                                },
                              }}
                              className={classes.loginFormInput}
                              onChange={(event) => {
                                // handleOnChangeEmail(event.target.value);
                                field.onChange(event);
                                if (event.target.value.length !== 0) {
                                  setActivateEmailCloseIcon(true);
                                } else {
                                  setActivateEmailCloseIcon(false);
                                }
                              }}
                            />
                          )}
                        />

                        {errors.email && (
                          <Box className={classes.userNameError}>
                            <p style={{ padding: '0', margin: '0' }}>
                              {isValidateCorporateName &&
                                t('common.validationError.email.maxLength')}
                              {!isValidateCorporateName &&
                                errors.email &&
                                errors.email.message}
                            </p>
                          </Box>
                        )}
                      </Box>
                      // </ClickAwayListener>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="corporate-profile-col">
              <CorporatePageSubscriptionCard
                currentPlan={
                  subscriptionInfo.currentPlan
                    ? subscriptionInfo.currentPlan
                    : '-'
                }
                /* eslint-disable no-nested-ternary */
                trialEnd={
                  subscriptionInfo.trialFlag
                    ? subscriptionInfo.expiredDate
                    : subscriptionInfo.nextBillingDate
                    ? subscriptionInfo.nextBillingDate
                    : '-'
                }
                trialFlag={subscriptionInfo.trialFlag}
                onClickBtn={onClickSubscriptionBtn}
                isCallingApi={isCallingApi}
                shopifyFeatureEnabled={accountInfo.shopifyFeatureEnabled}
              />
              <CorporatePageBtnCard
                title={t('corporateProfile.label.orderHistory')}
                icon={<OrderHistory />}
                btnText={t('corporateProfile.button.viewDetails')}
                onClickBtn={onClickOrderHistoryBtn}
                isCallingApi={isCallingApi}
                shopifyFeatureEnabled={accountInfo.shopifyFeatureEnabled}
              />
              <CorporatePageBtnCard
                title={t('corporateProfile.label.paymentInfo')}
                icon={<PaymentInfo />}
                btnText={t('corporateProfile.button.viewUpdate')}
                onClickBtn={onClickPaymentInfoBtn}
                isCallingApi={isCallingApi}
                isDisable
                shopifyFeatureEnabled={accountInfo.shopifyFeatureEnabled}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </PersonalSettingsTemplate>
  );
};
export default CorporateProfilePage;
