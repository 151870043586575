import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAccessKey from '../hooks/useAccessKey';
import { PAGE_PATH_NAME } from '../constants/constants';
import useLogin from '../hooks/useLogin';
// eslint-disable-next-line
import { LoginInput } from '../types/inputs/loginInput';
import PTloader from '../components/Atoms/ptloader/PTloader';
import callApiUtil from './callApiUtil';

/**
 * Only Admin user can access
 * @returns
 */
const AuthRoute = () => {
  const { accessKey } = useAccessKey();
  /* eslint-disable */

  const { pathname } = useLocation();

  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const { onClickLogin, isCallingApi } = useLogin();
  const [localIsLoading, setLocalIsLoading] = React.useState(true);

  const handleToken = React.useCallback(async () => {
    try {
      const token = localStorage.getItem('OktaToken');


      if (!token) {
        // No token in localStorage, get a new one silently
        const newToken = await getAccessTokenSilently();
        localStorage.setItem('OktaToken', newToken);

        callApiUtil.defaults.headers.common[
          'Auth0-Authorization'
        ] = `Auth0-Token ${newToken}`;
        handleNewToken();

        
        if (!accessKey || accessKey === '') {
          const loginInfo: LoginInput = {
            accessToken: newToken,
          };
          onClickLogin(loginInfo);
        }
      } else {
        const tokenDecoded = JSON.parse(atob(token.split('.')[1]));
        const expiryTime = tokenDecoded.exp * 1000;
        const currentTime = new Date().getTime();


        // if valid token get a new one to make the session time 30 minute
        if (currentTime < expiryTime) {
          const newToken = await getAccessTokenSilently();
          localStorage.setItem('OktaToken', newToken);
          callApiUtil.defaults.headers.common[
            'Auth0-Authorization'
          ] = `Auth0-Token ${newToken}`;
          handleNewToken();
        } else {
          // Token has expired, use the same token to show modal 
          callApiUtil.defaults.headers.common[
            'Auth0-Authorization'
          ] = `Auth0-Token ${token}`;
        }

        if (!accessKey || accessKey === '') {
          const loginInfo: LoginInput = {
            accessToken: token,
          };
          onClickLogin(loginInfo);
        }
      }
    } catch (error) {
      console.error('Error handling token:', error);
    } finally {
      setLocalIsLoading(false);
    }
  }, []);

  const handleNewToken = () => {
    const token = localStorage.getItem('OktaToken');
    if (!token) return; // No token available to renew
  
    const tokenDecoded = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = tokenDecoded.exp * 1000;
    const currentTime = new Date().getTime();
    const timeLeft = expiryTime - currentTime;
    const renewTime = timeLeft - 1000 * 2 * 60;

    if (renewTime <= 0) {

      return;
    }else{
      setTimeout(async () => {
          const newToken = await getAccessTokenSilently({ cacheMode: 'off' });
          localStorage.removeItem('OktaToken');
          localStorage.setItem('OktaToken', newToken);
          callApiUtil.defaults.headers.common[
            'Auth0-Authorization'
          ] = `Auth0-Token ${newToken}`;
        
        handleNewToken(); 
      }, renewTime);
    }
  
   
  };
  
  React.useEffect(() => {
    setTimeout(() => {
      if (
        !isLoading &&
        !isAuthenticated &&
        !localStorage.getItem('OktaToken')
      ) {
        localStorage.removeItem('OktaToken');
        loginWithRedirect();
      } else if (!isLoading) {
        handleToken();
      }
    }, 0);
  }, [isLoading]);

  return isLoading || isCallingApi || localIsLoading ? (
    <>
      <PTloader />
    </>
  ) : accessKey || accessKey !== '' ? (
    <Outlet />
  ) : (
    <Navigate to={PAGE_PATH_NAME.DASHBOARD} state={{ pageSrc: pathname }} />
  );
};

export default AuthRoute;
